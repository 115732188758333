
<template>
      <div class="bg-white pb-8 sm:pb-8">
        <div class="mx-auto max-w-2xl py-2">
      <a href="/billing/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Dashboard <span aria-hidden="true">→</span></a>
    </div>

      <div class="mx-auto mt-8 max-w-7xl px-6 lg:px-8">
        <p class="text-left">Number of students without a Hero ID: {{ studentsWithoutHeroIdCount }}</p>
        <p class="text-left">Fetching students... this process is slow</p>

      
      <!-- Loading Animation -->
      <div v-if="isLoading" class="flex flex-col justify-center items-center h-screen">
        <div class="mb-4 text-xl font-bold">Loading...</div>
        <div class="loader"></div>
      </div>

      <!-- Students without Hero ID Table -->
      <table v-if="!isLoading" class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th>Legal Name</th>
            <th>MIS ID</th>
            <th>Matched Name</th>
            <th>Family Code</th>
            <th>Hero ID</th>
            <th>Contact 1 Email</th>
            <th>Sync</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="student in studentsWithoutHeroId" :key="student.id">
            <td>{{ student.forename }} {{ student.surname }}</td>
            <td>{{ student.mis_id }}</td>
            <td>{{ student.firstNameLegal }} {{ student.lastNameLegal }}</td>
            <td>{{ student.familyCode }}</td>
            <td>{{ student.heroId }}</td>
            <td>{{ student.contact1Email }}</td>
            <td><button @click="syncStudent(student)"  class="rounded-md bg-indigo-600 px-1 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
>Sync</button></td>
          </tr>
          <tr v-if="studentsWithoutHeroId.length === 0">
            <td colspan="7">No students without Hero ID found.</td>
          </tr>
        </tbody>
      </table>

      <!-- Students with Hero ID Table -->
      <table v-if="!isLoading" class="min-w-full divide-y divide-gray-300 mt-8">
        <thead>
          <tr>
            <th>Legal Name</th>
            <th>MIS ID</th>
            <th>Matched Name</th>
            <th>Family Code</th>
            <th>Hero ID</th>
            <th>Contact 1 Email</th>
            <!-- <th>Sync</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="student in studentsWithHeroId" :key="student.id">
            <td>{{ student.forename }} {{ student.surname }}</td>
            <td>{{ student.mis_id }}</td>
            <td>{{ student.firstNameLegal }} {{ student.lastNameLegal }}</td>
            <td>{{ student.familyCode }}</td>
            <td>{{ student.heroId }}</td>
            <td>{{ student.contact1Email }}</td>
            <!-- <td><button @click="syncStudent(student)">Sync</button></td> -->
          </tr>
          <tr v-if="studentsWithHeroId.length === 0">
            <td colspan="7">No students with Hero ID found.</td>
          </tr>
        </tbody>
      </table>


    </div>
    </div>

  </template>
  <script setup>
import axios from 'axios';
import { ref, onMounted, computed } from 'vue'
import wondeService from '../../api/wondeService'

const wondeStudents = ref([]);
const localStudents = ref([]);
const students = ref([]);
const schoolId = ref('A1461335658'); // Hardcoded school ID
const isLoading = ref(false); // Loading state


const generateFamilyCode = (surname) => {
  const year = new Date().getFullYear();
  return `${surname.substring(0, 4).toUpperCase()}${year}`;
};

const syncStudent = async (student) => {
  try {
    const familyCode = generateFamilyCode(student.surname);
    const studentData = {
      firstNameLegal: student.forename, // Adjusted to match your student object structure
      lastNameLegal: student.surname, // Adjusted to match your student object structure
      heroId: student.mis_id, // Ensure this property exists or adjust accordingly
      familyCode: familyCode,
      // Add other fields as necessary, setting them to default values or null
    };

    await axios.post('/api/createStudent', {
      studentData: studentData,
    });

    alert('Student synced successfully!');
  } catch (error) {
    console.error('Failed to sync student:', error);
    alert('Failed to sync student.');
  }
};

const studentsWithoutHeroIdCount = computed(() => {
  return students.value ? students.value.filter(student => !student.heroId || String(student.heroId).trim() === '').length : 0;
});

const studentsWithoutHeroId = computed(() => {
  return students.value ? students.value.filter(student => !student.heroId || String(student.heroId).trim() === '') : [];
});

const studentsWithHeroId = computed(() => {
  return students.value ? students.value.filter(student => student.heroId && String(student.heroId).trim() !== '') : [];
});


// Fetch students from Wonde
const fetchWondeStudents = async () => {
  try {
    const response = await wondeService.getStudents(schoolId.value);
    wondeStudents.value = response.data;
    console.log('Fetched students data:', wondeStudents.value); // Log the full array of students data

  } catch (error) {
    console.error('Failed to fetch students from Wonde:', error);
  }
};

// Fetch students from your API endpoint
const fetchStudents = async () => {
  try {
    const response = await axios.get('/api/getStudents');
    localStudents.value = response.data;
  } catch (error) {
    console.error('Failed to fetch students:', error);
  }
};

onMounted(async () => {
  console.log('Page loaded'); // Log when the page loads
  isLoading.value = true; // Start loading


  await fetchWondeStudents();
  await fetchStudents();

  // Merge and match students based on names
  students.value = wondeStudents.value.map(wondeStudent => {
    // Normalize names for comparison
    const wondeStudentName = `${wondeStudent.legal_forename} ${wondeStudent.legal_surname}`.toLowerCase();

    // Find a matching student in localStudents based on normalized names
    const localStudent = localStudents.value.find(local => {
      const localStudentName = `${local.firstNameLegal} ${local.lastNameLegal}`.toLowerCase();
      return wondeStudentName === localStudentName;
    });

    // Merge data from both sources for the matched student
    // Adjust the properties as needed based on your data structure
    return {
      // Data from wondeStudent
      forename: wondeStudent.legal_forename,
      surname: wondeStudent.legal_surname,
      mis_id: wondeStudent.mis_secondary_id,

      // Data from localStudent if a match was found, otherwise default to empty strings or null
      familyCode: localStudent ? localStudent.familyCode : '',
      heroId: localStudent ? localStudent.heroId : '',
      contact1Email: localStudent ? localStudent.contact1Email : '',
      // Use legal names from localStudent, fallback to wondeStudent names if not available
      firstNameLegal: localStudent ? localStudent.firstNameLegal : wondeStudent.legal_forename,
      lastNameLegal: localStudent ? localStudent.lastNameLegal : wondeStudent.legal_surname,
    };
  });
  isLoading.value = false; // End loading
});
  </script>


<style>
/* Add your loading animation styles here */
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
