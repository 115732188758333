
<template>
    <div class="bg-white pb-24 sm:pb-32">
      <div class="mx-auto mt-40 max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">School Fees – 2024</p>
          <p class="mt-6 text-lg leading-8 pb-12 text-gray-600">Port Vila International School is an independent school and is financially dependent wholly on fees and levies paid by
students attending the school. School Fees and levies must be paid within timeframes as set out in this policy. This
Schedule should be read in conjunction with our Enrolment Terms and Conditions.</p>

            <a href="/PVIS-Schedule-of-Fees-2024.pdf" download>
            <button type="button" class="rounded-md bg-custom-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:custom-blue-pale focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-custom-blue">
                Download our Schedule of Fees 2024 [PDF]
            </button>
</a>
        </div>

    </div>
    </div>
  </template>
  <script setup>

  </script>
