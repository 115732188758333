<template>
  <div class="bg-white pb-24 sm:pb-32">
    <div class="mx-auto mt-40 max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="lg:pr-4">
          <div class="relative overflow-hidden rounded-3xl bg-gray-900 shadow-2xl lg:max-w-lg">
            <iframe width="512" height="768" src="https://embed.windy.com/embed2.html?lat=-17.902&lon=168.338&detailLat=-17.757&detailLon=166.657&width=512&height=768&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=true&calendar=now&pressure=true&type=map&location=coordinates&detail=&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1" frameborder="0"></iframe>
          </div>
        </div>
        <div>
          <div class="text-base leading-7 text-gray-700 lg:max-w-lg">
            <p class="text-base font-semibold leading-7 text-indigo-600">Safe Choices, Informed Steps</p>
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Cyclone and Natural Disaster Preparedness</h1>
            <div class="max-w-xl">
              <p class="mt-6">            Vanuatu is susceptible to natural events. At PVIS, we prioritize our community's safety with comprehensive plans and procedures for responding to significant natural disasters, including cyclones and tsunamis.</p>
              <p class="mt-8">During the cyclone season from November to May, our safety measures align with advisories from the National Disaster Management Office (NDMO). We take decisive action based on the alert levels issued, ensuring the safety of our students and staff.
            </p>
              <p class="mt-8">In the event of a tsunami warning, we have a clear evacuation plan to the University of the South Pacific (USP). "If the earthquake is long and strong, then get gone" is our motto, guiding immediate and proactive evacuation measures.
            </p>
            <p class="mt-8">Keeping our records current is crucial for emergency response. We request that parents update contact information promptly to ensure swift communication in any crisis.
            [LINK TO UPDATE FORM]</p>
            <p class="mt-8">
            Regular drills and first aid training for staff are part of our commitment to preparedness. These practices are essential for ensuring that everyone knows how to act swiftly and safely during emergencies.
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto mt-16 max-w-7xl px-6 lg:px-8">
      <h1 class="my-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Useful Resources</h1>

      <div class="mx-auto max-w-2xl lg:max-w-none">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
          <div v-for="feature in features" :key="feature.name" class="flex flex-col">
            <dt class="text-base font-semibold leading-7 text-gray-900">
              {{ feature.name }}
            </dt>
            <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">{{ feature.description }}</p>
              <p class="mt-2">
                <a :href="feature.href" class="text-sm font-semibold leading-6 text-indigo-600">Learn more <span aria-hidden="true">→</span></a>
              </p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { InboxIcon, TrashIcon, UsersIcon } from '@heroicons/vue/24/outline'

const features = [
  {
    name: 'Vanuatu Meterology and Geohazards Department (VMGD)',
    description:
      'Non quo aperiam repellendus quas est est. Eos aut dolore aut ut sit nesciunt. Ex tempora quia. Sit nobis consequatur dolores incidunt.',
    href: 'https://www.vmgd.gov.vu/vmgd/index.php',
    icon: InboxIcon,
  },
  {
    name: 'VMGD Daily weather observations',
    description:
      'Vero eum voluptatem aliquid nostrum voluptatem. Vitae esse natus. Earum nihil deserunt eos quasi cupiditate. A inventore et molestiae natus.',
    href: 'https://www.vmgd.gov.vu/vmgd/index.php/forecast-division/public-forecast/media',
    icon: UsersIcon,
  },
  {
    name: 'VMGD Cyclone Tracking Map',
    description:
      'Et quod quaerat dolorem quaerat architecto aliquam accusantium. Ex adipisci et doloremque autem quia quam. Quis eos molestiae at iure impedit.',
    href: 'https://www.vmgd.gov.vu/vmgd/index.php/forecast-division/tropical-cyclone/vanuatu-cyclone-tracking-map',
    icon: TrashIcon,
  },
  {
    name: 'Joint Typhoon Warning Center',
    description:
      'Et quod quaerat dolorem quaerat architecto aliquam accusantium. Ex adipisci et doloremque autem quia quam. Quis eos molestiae at iure impedit.',
    href: 'https://www.metoc.navy.mil/jtwc/jtwc.html',
    icon: TrashIcon,
  },
  {
    name: 'Get Tsunami Ready (NZ)',
    description:
      'Et quod quaerat dolorem quaerat architecto aliquam accusantium. Ex adipisci et doloremque autem quia quam. Quis eos molestiae at iure impedit.',
    href: 'https://www.civildefence.govt.nz/get-ready/get-tsunami-ready/',
    icon: TrashIcon,
  },
  {
    name: 'US Tsunami Warning System',
    description:
      'Et quod quaerat dolorem quaerat architecto aliquam accusantium. Ex adipisci et doloremque autem quia quam. Quis eos molestiae at iure impedit.',
    href: 'https://www.tsunami.gov/',
    icon: TrashIcon,
  },
  {
    name: 'Joint Australian Tsunami Warning Centre',
    description:
      'Et quod quaerat dolorem quaerat architecto aliquam accusantium. Ex adipisci et doloremque autem quia quam. Quis eos molestiae at iure impedit.',
    href: 'http://www.bom.gov.au/tsunami/',
    icon: TrashIcon,
  },
  {
    name: 'Earthquakes @GA',
    description:
      'Et quod quaerat dolorem quaerat architecto aliquam accusantium. Ex adipisci et doloremque autem quia quam. Quis eos molestiae at iure impedit.',
    href: 'https://earthquakes.ga.gov.au/',
    icon: TrashIcon,
  },
  {
    name: 'Australian Bureau of Meteorology satellite weather',
    description:
      'Et quod quaerat dolorem quaerat architecto aliquam accusantium. Ex adipisci et doloremque autem quia quam. Quis eos molestiae at iure impedit.',
    href: 'http://satview.bom.gov.au/',
    icon: TrashIcon,
  },
]



</script>