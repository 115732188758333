<template>  
  <div class="bg-white ">
    <div class="relative z-0 isolate px-6 lg:px-8">
      <div class="absolute inset-x-0 -top-40 z-0 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#002B5C] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
      <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
<!--         <div class="hidden sm:mb-8 sm:flex sm:justify-center">
          <div class="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            Announcing our next round of funding. <a href="#" class="font-semibold text-indigo-600"><span class="absolute inset-0" aria-hidden="true" />Read more <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div> -->
        <div class="text-center">
          <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{{ content.title }}</h1>
          <p class="mt-6 text-lg pb-6 leading-8 text-gray-600">{{ content.subtitle }}</p>
          <p>{{ content.body }}</p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <!-- <a href="#" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Explore PVIS</a> -->
            <a href="/our-values" class="text-sm font-semibold leading-6 text-gray-900">Our Mission & Values <span aria-hidden="true">→</span></a>
          </div>
        </div>
      </div>
      <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
        <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useHead } from '@vueuse/head'

const content = ref({})

const fetchContent = async () => {
  try {
    const response = await axios.get(`https://cdn.contentful.com/spaces/8ayqhzh3p1ua/environments/master/entries?access_token=${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}&content_type=homepage`)
    //console.log('Fetched content:', response.data);
    if (response.data.items && response.data.items.length > 0) {
      content.value = response.data.items[0].fields
    } else {
      console.error('No content found');
      content.value = { title: 'Welcome', subtitle: 'Content not available', body: '' } // Default content
    }
  } catch (error) {
    console.error('Error fetching content from Contentful:', error);
    content.value = { title: 'Welcome', subtitle: 'Error loading content', body: '' } // Default content on error
  }
}
onMounted(() => {
  fetchContent()
})

useHead({
  title: 'Home - Port Vila International School',
  meta: [
    {
      name: 'description',
      content: 'Port Vila International School, a not-for-profit independent institution in Vanuatu, is dedicated to delivering excellence in education. We serve a dynamic community of international and Ni-Vanuatu families, offering a global education that incorporates Australian educational standards while embracing the context of our Pacific island location.'
    },
    {
      name: 'robots',
      content: 'index, follow'
    }
  ]
})

</script>