<template>
  <div class="bg-white pb-24 sm:pb-32">
    <div class="mx-auto mt-40 max-w-7xl px-6 lg:px-8">
      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ content.pageTitle }}</h2>
        <p class="mt-6 mb-6 text-lg leading-8 text-gray-600">{{ content.introduction }}</p>
      </div>
      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl mb-6 font-bold tracking-tight text-gray-900 sm:text-4xl">{{ content.constitution }}</h2>
        <a :href="content.constitutionLink" target="_blank">
            <button type="button" class="rounded-md bg-custom-blue mb-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:custom-blue-pale focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-custom-blue">
                PVIS Constitution
            </button>
          </a>
          
      </div>
<!-- Board Members -->
<div class="mx-auto lg:mx-0">
          <h2 class="text-3xl mt-6 font-bold tracking-tight text-gray-900 sm:text-4xl">{{ content.ourMembersTitle }}</h2>
        </div>
        <ul role="list" class="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <li v-for="member in content.members" :key="member.sys.id">
            <img class="aspect-[2/3] w-full rounded-2xl object-cover" :src="getImageUrl(member)" alt="" />
            <h3 class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ member.fields.name }}</h3>
            <p class="text-base leading-7 text-gray-600">{{ member.fields.title }}</p>
            <div v-if="member.fields.email" class="text-base leading-7 text-gray-600">
              <a :href="'mailto:' + member.fields.email" class="inline-block w-full text-blue-500 hover:underline">
                {{ member.fields.email }}
              </a>
            </div>
          </li>
        </ul>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'

const content = ref({})
const loading = ref(true);

const fetchContent = async () => {
  try {
    const response = await axios.get(`https://cdn.contentful.com/spaces/8ayqhzh3p1ua/environments/master/entries?access_token=${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}&content_type=board`)
    console.log('Fetched content:', response.data);
    if (response.data.items && response.data.items.length > 0) {
      const fields = response.data.items[0].fields;
      const assets = response.data.includes.Asset;
      
      content.value = {
        pageTitle: fields.pageTitle,
        introduction: fields.introduction,
        constitution: fields.constitution,
        constitutionLink: fields.constitutionLink,
        ourMembersTitle: fields.ourMembersTitle,
        members: fields.members.map(member => {
          const memberData = response.data.includes.Entry.find(entry => entry.sys.id === member.sys.id);
          if (memberData) {
            const photoAsset = assets.find(asset => asset.sys.id === memberData.fields.photo.sys.id);
            return {
              ...memberData,
              fields: {
                ...memberData.fields,
                photoUrl: photoAsset ? `https:${photoAsset.fields.file.url}` : null
              }
            };
          }
          return member;
        })
      };
    } else {
      console.error('No content found');
      content.value = { pageTitle: 'Our Board', introduction: 'Content not available', members: [] };
    }
  } catch (error) {
    console.error('Error fetching content from Contentful:', error);
    content.value = { pageTitle: 'Our Board', introduction: 'Error loading content', members: [] };
  } finally {
    loading.value = false;
  }
};

const getImageUrl = (member) => {
  return member.fields.photoUrl || member.fields.imageUrl || 'path/to/default/image.jpg';
};


onMounted(() => {
  fetchContent();
});



</script>