
<template>
    <div class="bg-white pb-12 sm:pb-32">
      <div class="mx-auto mt-40 max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <h2 class="text-base font-semibold leading-7 text-custom-blue">PVIS Yearbook 2023</h2>
        </div>
      </div>
          <div class="mx-auto lg:mx-0">
            <div style="position:relative;padding-top:max(60%,326px);height:0;width:100%"><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowfullscreen="true" style="position:absolute;border:none;width:100%;height:100%;left:0;right:0;top:0;bottom:0;" src="https://e.issuu.com/embed.html?d=pvis_magazine_2023_for_issu&u=pvis"></iframe></div>          </div>
    


    </div>
  </template>
  <script setup>

  </script>
