<template>
  <div class="bg-white pb-24 sm:pb-32">
    <div class="mx-auto mt-40 max-w-7xl px-6 lg:px-8 md:divide-y md:divide-gray-200">
      <div class="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
        <div class="md:pr-14">
          <div class="flex items-center">
            <button type="button" @click="goToToday" class="your-classes">Today</button>
            <h2 class="flex-auto text-sm font-semibold text-gray-900">{{ currentMonthYear }}</h2>
            <button type="button" @click="goToPreviousMonth" class="your-classes">
              <span class="sr-only">Previous month</span>
              <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button type="button" @click="goToNextMonth" class="your-classes">
              <span class="sr-only">Next month</span>
              <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>
          <div class="mt-2 grid grid-cols-7 text-sm">
            <div v-for="(day, dayIdx) in days" :key="day.date" :class="[dayIdx > 6 && 'border-t border-gray-200', 'py-2']">
              <button type="button" :class="[day.isSelected && 'text-white', !day.isSelected && day.isToday && 'text-black bg-lime-400', !day.isSelected && !day.isToday && day.isCurrentMonth && 'text-gray-900', !day.isSelected && !day.isToday && !day.isCurrentMonth && 'text-gray-400', day.isSelected && day.isToday && 'bg-indigo-600', day.isSelected && !day.isToday && 'bg-custom-blue', !day.isSelected && 'hover:bg-gray-200', (day.isSelected || day.isToday) && 'font-semibold', 'mx-auto flex h-8 w-8 items-center justify-center rounded-full']">
                <time v-if="day.date" :datetime="day.date">{{ day.date.split('-').pop().replace(/^0/, '') }}</time>
              </button>
            </div>
          </div>
        </div>
        <section class="mt-12 md:mt-0 md:pl-14">
          <h2 class="text-base font-semibold leading-6 text-gray-900">{{ currentMonthYear }}</h2>
          <ol class="mt-4 space-y-1 text-sm leading-6 text-gray-500">
            <li v-for="event in filteredEvents" :key="event.id" class="group flex items-center space-x-4 rounded-xl px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-100">
              <div class="flex-auto">
                <time :datetime="event.Datetime">{{ formatDate(event.Datetime) }}</time>
                <p class="text-gray-900">{{ event.name }}</p>
                <p class="mt-0.5">{{ event.type }}</p>
              </div>
            </li>
          </ol>
        </section>
      </div>
    </div>
    <div class="mx-auto mt-10 max-w-7xl px-6 lg:px-8">
  <hr class="my-4">
  <h2 class="text-base font-semibold leading-6 text-gray-900">{{ currentYear }}</h2>

  <div class="grid grid-cols-6 gap-4">
  <div v-for="(monthEvents, index) in eventsByMonth.slice(0, 6)" :key="index">
    <h2 class="text-base font-semibold mb-2 leading-6 text-gray-900">{{ monthEvents.month }}</h2>
    <ul>
      <li class="mb-4" v-for="event in monthEvents.events" :key="event.id">
        <div >
        <time class="text-xs font-semibold" :datetime="event.Datetime">{{ formatDate(event.Datetime) }}</time>
        <p class="text-gray-900">{{ event.name }}</p>
        <p class="text-xs">{{ event.type }}</p>
      </div>
      </li>
    </ul>
  </div>
</div>
<div class="grid grid-cols-6 gap-4">
  <div v-for="(monthEvents, index) in eventsByMonth.slice(6)" :key="index+6">
    <h2 class="text-base font-semibold mb-2 leading-6 text-gray-900">{{ monthEvents.month }}</h2>
    <ul>
      <li class="mb-4" v-for="event in monthEvents.events" :key="event.id">
        <time class="text-xs font-semibold" :datetime="event.Datetime">{{ formatDate(event.Datetime) }}</time>
        <p class="text-gray-900">{{ event.name }}</p>
        <p class="text-xs">{{ event.type }}</p>
      </li>
    </ul>
  </div>
</div>
</div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import {  } from '@heroicons/vue/24/outline'

let currentDate = ref(new Date(new Date().getTime() + 11 * 60 * 60 * 1000))

const goToToday = () => {
  currentDate.value = new Date();
}

let currentMonthYear = computed(() => {
  let monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return monthNames[currentDate.value.getMonth()] + ' ' + currentDate.value.getFullYear();
})

const goToPreviousMonth = () => {
  currentDate.value = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth() - 1);
}

const goToNextMonth = () => {
  currentDate.value = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth() + 1);
}

let days = computed(() => {
  let date = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth(), 1);
  date.setHours(date.getHours() + 11); // Adjust the date for the GMT+11 timezone
  let today = new Date(new Date().getTime());
  let days = [];

  // Adjust the day of the week for the GMT+11 timezone
  let dayOfWeek = date.getDay();
  if (dayOfWeek < 0) dayOfWeek += 7;

  // Add empty slots for the days before the first day of the month
  for (let i = 0; i < dayOfWeek; i++) {
    days.push({ date: null });
  }

  while (date.getMonth() === currentDate.value.getMonth()) {
    let isToday = date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    let isSelected = events.some(event => new Date(event.Datetime).toDateString() === date.toDateString());
    days.push({ date: date.toISOString().split('T')[0], isCurrentMonth: true, isToday: isToday, isSelected: isSelected });
    date.setDate(date.getDate() + 1);
  }
  return days;
})

let currentYear = computed(() => {
  return new Date().getFullYear();
});

const formatDate = (dateString) => {
  let date = new Date(dateString);
  let day = String(date.getDate()).padStart(2, '0');
  let month = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  let year = date.getFullYear();

  return day + '-' + month + '-' + year;
}

let filteredEvents = computed(() => {
  let nextMonth = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth() + 1);
  return events.filter(event => {
    let eventDate = new Date(event.Datetime);
    return eventDate >= currentDate.value && eventDate < nextMonth;
  }).sort((a, b) => new Date(a.Datetime) - new Date(b.Datetime));
});

let eventsByMonth = computed(() => {
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let eventsByMonth = months.map(month => {
    return {
      month: month,
      events: events.filter(event => new Date(event.Datetime).getMonth() === months.indexOf(month))
    };
  });
  return eventsByMonth;
});


const events = [
{
  "id": 1,
  "name": "New Staff Induction Day",
  "Datetime": "2024-01-26T00:00",
  "type": "Teachers only day"
},
{
  "id": 2,
  "name": "Staff Development Days",
  "Datetime": "2024-01-29T00:00",
  "type": "Teachers only day"
},
{
  "id": 14,
  "name": "Staff Development Days",
  "Datetime": "2024-01-30T00:00",
  "type": "Teachers only day"
},
{
  "id": 3,
  "name": "Classes commence for Term 1",
  "Datetime": "2024-01-31T00:00",
  "type": "School Day"
},
{
  "id": 4,
  "name": "Term 1 End",
  "Datetime": "2024-03-28T00:00",
  "type": "School Day"
},
{
  "id": 5,
  "name": "Father Lini Day",
  "Datetime": "2024-02-21T00:00",
  "type": "Public Holiday"
},
{
  "id": 6,
  "name": "Custom Chief Day",
  "Datetime": "2024-03-05T00:00",
  "type": "Public Holiday"
},
{
  "id": 7,
  "name": "Good Friday",
  "Datetime": "2024-03-29T00:00",
  "type": "Public Holiday"
},
{
  "id": 8,
  "name": "Easter Monday",
  "Datetime": "2024-04-01T00:00",
  "type": "Public Holiday"
},
{
  "id": 9,
  "name": "Term 2",
  "Datetime": "2024-04-15T00:00",
  "type": "Term Start"
},
{
  "id": 10,
  "name": "Classes commence",
  "Datetime": "2024-04-15T00:00",
  "type": "School Day"
},
{
  "id": 11,
  "name": "Labour Day",
  "Datetime": "2024-05-01T00:00",
  "type": "Public Holiday"
},
{
  "id": 12,
  "name": "Ascension Day",
  "Datetime": "2024-05-09T00:00",
  "type": "Public Holiday"
},
{
  "id": 13,
  "name": "Staff Professional Development Day",
  "Datetime": "2024-07-12T00:00",
  "type": "Teachers only day"
},
{
"id": 14,
"name": "Term 2 End",
"Datetime": "2024-06-21T00:00",
"type": "School Day"
},
{
  "id": 15,
  "name": "Term 3",
  "Datetime": "2024-07-15T00:00",
  "type": "Term Start"
},
{
  "id": 16,
  "name": "Classes commence",
  "Datetime": "2024-07-15T00:00",
  "type": "School Day"
},
{
  "id": 17,
  "name": "Children’s Day",
  "Datetime": "2024-07-24T00:00",
  "type": "Public Holiday"
},
{
  "id": 18,
  "name": "Independence Day Holiday",
  "Datetime": "2024-07-30T00:00",
  "type": "Public Holiday"
},
{
  "id": 19,
  "name": "Assumption Day",
  "Datetime": "2024-08-15T00:00",
  "type": "Public Holiday"
},

{
  "id": 21,
  "name": "Term 3 End",
  "Datetime": "2024-09-20T00:00",
  "type": "School Day"
},
{
  "id": 24,
  "name": "Year 12 SACE Study Week",
  "Datetime": "2024-09-30T00:00",
  "type": "Study Week"
},
{
  "id": 25,
  "name": "Year 12 SACE Study Week",
  "Datetime": "2024-10-01T00:00",
  "type": "Study Week"
},
{
  "id": 26,
  "name": "Year 12 SACE Study Week",
  "Datetime": "2024-10-02:00",
  "type": "Study Week"
},
{
  "id": 27,
  "name": "Year 12 SACE Study Week",
  "Datetime": "2024-10-03T00:00",
  "type": "Study Week"
},
{
  "id": 28,
  "name": "Year 12 SACE Study Week",
  "Datetime": "2024-10-04T00:00",
  "type": "Study Week"
},
{
  "id": 20,
  "name": "Constitution Day",
  "Datetime": "2024-10-05T00:00",
  "type": "Public Holiday"
},
{
  "id": 22,
  "name": "Term 4",
  "Datetime": "2024-10-07T00:00",
  "type": "Term Start"
},
{
  "id": 23,
  "name": "Classes commence",
  "Datetime": "2024-10-07T00:00",
  "type": "School Day"
},
{
  "id": 29,
  "name": "End of year concert",
  "Datetime": "2024-12-11T00:00",
  "type": "Concert"
},
{
  "id": 30,
  "name": "Term 4 End",
  "Datetime": "2024-12-11T00:00",
  "type": "School Day"
},
{
  "id": 31,
  "name": "Staff Professional Development Day",
  "Datetime": "2024-12-12T00:00",
  "type": "Teachers only day"
},
{
  "id": 32,
  "name": "Unity Day",
  "Datetime": "2024-11-29T00:00",
  "type": "Public Holiday"
}
]


</script>