<template>
  <div class="mx-auto max-w-2xl py-2">
    <a href="/billing/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Dashboard <span aria-hidden="true">→</span></a>
  </div>
  <div class="relative isolate px-6 pt-14 lg:px-8">
    <form @submit.prevent="submitForm">
      <label for="billingYear">Billing Year</label>
      <input type="number" id="billingYear" v-model="billingYear" placeholder="YYYY" min="2000" max="2099">
      <div v-for="(date, index) in invoiceDates" :key="index">
        <label :for="'term' + (index + 1)">Term {{ index + 1 }} Invoice Date</label>
        <input type="date" :value="date" @input="updateDate($event, index)" :id="'term' + (index + 1)">
      </div>
      <label for="paymentTerm">Payment Term</label>
      <select id="paymentTerm" v-model="paymentTerm">
        <option value="14">14 days</option>
        <option value="30">30 days</option>
      </select>
      <button type="submit">Save Settings</button>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const invoiceDates = ref(['', '', '', '']);
const paymentTerm = ref('');
const billingYear = ref(''); // Add this line


const updateDate = (event, index) => {
  invoiceDates.value[index] = event.target.value;
};

const submitForm = async () => {
  const settingsData = {
    invoiceDates: invoiceDates.value,
    paymentTerm: paymentTerm.value,
    billingYear: billingYear.value,

  };

  try {
    const response = await axios.post('/api/updateSettings', settingsData);
    console.log(response.data.message);
  } catch (error) {
    console.error('Error updating settings:', error);
  }
};

const fetchSettings = async () => {
  try {
    const response = await axios.get('/api/getSettings');
    if (response.data) {
      invoiceDates.value = response.data.invoiceDates;
      paymentTerm.value = response.data.paymentTerm;
      billingYear.value = response.data.billingYear;

    }
  } catch (error) {
    console.error('Error fetching settings:', error);
  }
};

onMounted(fetchSettings);
</script>