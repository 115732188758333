<template>  
    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <img class="mx-auto h-24 w-auto" :src="logoUrl" alt="Logo"  />
        <div class="mt-5 p-4 text-center text-white bg-red-500 rounded-lg shadow">
          <p class="text-xs font-semibold">This page is strictly for internal use only.</p>
          <p class="text-xs mt-2 font-bold">DO NOT SHARE YOUR LOGIN</p>
        </div>
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to PVIS Billing</h2>
        
      </div>
  
      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form class="space-y-6" @submit.prevent="login">
          <div>
            <label for="email" class="block text-sm text-left font-medium leading-6 text-gray-900">Email address</label>
            <div class="mt-2">
              <input v-model="email" id="email" name="email" type="email" autocomplete="email" required="true" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
  
          <div>
            <div v-if="error" class="mt-5 p-4 text-center text-white bg-red-500 rounded-lg shadow">
              <p class="text-xs font-semibold">{{ error }}</p>
            </div>
            <div class="flex items-center justify-between">
              <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
              <div class="text-sm">
                <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a>
              </div>
            </div>
            <div class="mt-2">
              <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required="true" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
  
          <div>
            <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      <span v-if="isLoading">Authenticating...</span>
      <span v-else-if="isLoggedIn"><a href="/billing/dashboard">Go to Dashboard</a></span>
      <span v-else>Log in</span>
    </button>
          </div>
        </form>
  
      </div>
    </div>
  </template>
  
<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase'
import logoUrl from '../assets/PVIS-logo-blue.svg'

let email = ref('')
let password = ref('')
let error = ref('')
let isLoading = ref(false)
let isLoggedIn = ref(false) 


const router = useRouter()

const login = async () => {
  console.log('Login button clicked');
  isLoading.value = true
  try {
    await signInWithEmailAndPassword(auth, email.value, password.value)
    isLoggedIn.value = true // set login state
    router.push('/billing/dashboard')
  } catch (e) {
    error.value = 'Failed to log in. Please check your email and password.'
    console.error('Error signing in:', e)
  } finally {
    isLoading.value = false
  }
}
</script>