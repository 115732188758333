<template>
  <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl pb-6 font-bold tracking-tight text-gray-900 sm:text-4xl">Enrolment Process</h2>
      </div>

      <nav aria-label="Progress" class="mb-12">
    <ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
      <li v-for="step in steps" :key="step.name" class="md:flex-1">
        <a v-if="step.status === 'complete'" :href="step.href" target="_blank" class="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
          <span class="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">{{ step.id }}</span>
          <span class="text-sm font-medium mb-6">{{ step.name }}</span>
          <span class="font-medium text-gray-500 text-xs">{{ step.description }}</span>
        </a>
        <a v-else :href="step.href" target="_blank" class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
          <span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">{{ step.id }}</span>
          <span class="text-sm font-medium mb-6">{{ step.name }}</span>
          <span class="font-medium text-gray-500 text-xs">{{ step.description }}</span>
        </a>
      </li>
    </ol>
  </nav>

  <div class="mx-auto lg:mx-0">
        <a href="https://enrolments.linc-ed.com/apply/NZ/vanuatu1" target="_blank">
            <button type="button" class="rounded-md bg-custom-blue mb-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:custom-blue-pale focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-custom-blue">
                Online enrolment Form
            </button>
          </a>
          
          <div>
            <a href="/PVIS-Enrolment-Terms-and-Conditions-2024.pdf" download>
            <button type="button" class="rounded-md bg-custom-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:custom-blue-pale focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-custom-blue">
                Download our Enrolment Terms and Conditions [PDF]
            </button>
          </a>
          </div>
        <p class="mt-2 pb-6 leading-7 text-xs text-gray-600">For inquiries or assistance, please contact us at admin@pvis.edu.vu. To apply, please fill out the online form.</p>
          
      </div>


      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div>
          <h2 class="text-base font-semibold leading-7 text-indigo-600">When to Apply?</h2>
          <p class="mt-6 text-base leading-7 text-gray-600">Thank you for considering Port Vila International School - Vanuatu. PVIS operates on a southern hemisphere academic timetable (January to December). Age and year level guidance documents are available for various countries.</p>
          <p class="mt-6 pb-6 text-base leading-7 text-gray-600">Families may inquire or apply at any time; however, we may have limited spaces. We encourage families to submit their applications as early as possible to secure a place for their child. If a year level is full, your child will be placed on a waiting list.</p>

        </div>
        <div>
          <h2 class="text-base font-semibold leading-7 text-indigo-600">Limited Spaces Due to Classroom Capacity</h2>
          <p class="mt-6 pb-6 text-base leading-7 text-gray-600">PVIS is a highly sought-after school. Applying does not guarantee entry as applications often exceed the number of places available. Early enrolment is strongly encouraged to avoid disappointment.</p>
          <p>Our commitment to maintaining an environment where each child receives the attention and support they need for both academic growth and personal wellbeing, along with the constraints of our campus, necessitates careful management of the number of students at each year level.</p>
        </div>
        <div>
          <h2 class="text-base font-semibold leading-7 text-indigo-600">Priority Enrolments</h2>
          <p class="mt-6 pb-6 text-base leading-7 text-gray-600"> Given to organisations with debentures, followed by siblings and returning students.</p>

        </div>

      </div>
      

    </div>
  </div>
</template>

  <script setup>
const steps = [
  { id: 'Step 1', name: 'Schedule a Personal Tour', description: 'Experience our campus and understand the learning environment we offer (not compulsory, but recommended).', href: '#', status: 'complete' },
  { id: 'Step 2', name: 'Complete an Application for Enrolment', description: "Begin your child's admission journey by filling out the necessary forms and information.", href: 'https://enrolments.linc-ed.com/apply/NZ/vanuatu1', status: 'upcoming' },
  { id: 'Step 3', name: 'Assessments', href: '#', description: 'Prospective students may need to participate in assessments as part of their application.', status: 'upcoming' },
  { id: 'Step 4', name: 'Attend an Interview with the Head of School or Principal', description: "Discuss your child's educational needs and our school's offerings.", href: '#', status: 'upcoming' },
  { id: 'Step 5', name: 'Experience Morning for Early Education Students', description: "A special session for young learners to get a feel for our school.", href: '#', status: 'upcoming' },
  { id: 'Step 6', name: 'Receive an Offer of Place', href: '#', description: 'Successful applicants will be notified with an offer.', status: 'upcoming' },
]
  </script>
