
<template>
    <div class="bg-white pb-24 sm:pb-32">
      <div class="mx-auto mt-40 max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-4xl lg:text-center">
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Yumi Strong</p>
          <p class="mt-6 text-lg leading-8 text-gray-600">Yumi Strong is a whole-school approach to student wellbeing and engagement that supports students on their journey throughout PVIS. This framework and its programs are guided by best practice in positive education and student wellbeing and has been developed alongside the following strategies, frameworks and policies:</p>

          <ul class="mt-6 list-disc list-inside space-y-2 text-gray-700 text-lg text-left">
            <li><a href="https://www.mindbank.info/item/6270" target="_blank" class="text-blue-500 hover:text-blue-800 underline">Vanuatu Mental Health Policy and Strategic Plan 2016-2020 (in development)</a></li>
            <li><a href="https://mjcs.gov.vu/images/policy/DID_Policy_2018-2025.pdf" target="_blank" class="text-blue-500 hover:text-blue-800 underline">National Disability Inclusive Development Policy 2018-2025</a></li>
            <li><a href="https://www.australiancurriculum.edu.au/f-10-curriculum/general-capabilities/" target="_blank" class="text-blue-500 hover:text-blue-800 underline">Australian Curriculum</a></li>
            <li><a href="https://pvisvanuatuorg.sharepoint.com/:b:/s/PVISBoard/EYljuaEn0vlOqr8mGYXqzRABOLSVGdXwOY41MU0iu-iGYA?e=tEaTDD" target="_blank" class="text-blue-500 hover:text-blue-800 underline">PVIS Strategic Plan 2018 to 2021</a></li>
            <li>PVIS Policies and procedures</li>
            <li><a href="https://positivepsychology.com/perma-model/" target="_blank" class="text-blue-500 hover:text-blue-800 underline">PERMA Theory of Wellbeing (Positive Emotion, Engagement, Relationships, Meaning, and Accomplishment)</a></li>
            <li><a href="https://studentwellbeinghub.edu.au/educators/framework/" target="_blank" class="text-blue-500 hover:text-blue-800 underline">Australian Student Wellbeing Framework</a></li>
            <li><a href="https://education.nsw.gov.au/student-wellbeing/whole-school-approach/wellbeing-framework-for-schools#What1" target="_blank" class="text-blue-500 hover:text-blue-800 underline">Wellbeing Framework for Schools (NSW)</a></li>
            <li><a href="https://www.education.vic.gov.au/school/teachers/health/Pages/default.aspx" target="_blank" class="text-blue-500 hover:text-blue-800 underline">Student Health and Wellbeing (VIC)</a> </li>
        </ul>

        </div>
        
    </div>

    <div class="mx-auto mt-16 max-w-6xl sm:mt-20 lg:mt-24 lg:max-w-6xl">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          <div class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">Wellbeing and education</dt>
            <dd class="mt-2 text-base leading-7 text-gray-600">Wellbeing is about the quality of our life. It includes our physical and mental health, as well as social elements like relationships and connections to our community. It also includes positive emotions, setting and achieving goals and problem solving. Research shows that wellbeing, or a lack of it, can affect students’ engagement and success in learning[1]. Schools which integrate wellbeing into their teaching and learning, policy and practice and community engagement best support students to develop, succeed and thrive at each stage of their development and learning.</dd>
          </div>

          <div class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">Coordination</dt>
            <dd class="mt-2 text-base leading-7 text-gray-600">Yumi Strong is coordinated by the Wellbeing Coordinator (Rose Lobban) in close consultation with the Student Coordinator (Miria Cretier), Secondary Health and Physical Education teacher and primary school teachers. The program is overseen by the Senior Leadership Team. Student leaders will be involved in the evolving design and implementation of the program.</dd>
          </div>

          <div class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">Community engagement</dt>
            <dd class="mt-2 text-base leading-7 text-gray-600">Preliminary input and feedback has been sought from staff and will also be sought from the Ministry of Education, the Ministry of Health, the MindCare Clinic, Vanuatu Women’s Clinic and other community organisations, as identified. Student, family, staff and broader community engagement and feedback will be sought throughout the first year of development and implementation, in line with best practice in continuous school improvement.</dd>
          </div>

          <div class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">Classroom programs</dt>
            <dd class="mt-2 text-base leading-7 text-gray-600">The PVIS Yumi Strong programs are designed to support students to be the best they can be at school, at home and in their future endeavours.
              <p class="mt-2">Classes are specifically allocated in Primary school and Year 7-8, while Years 9-10 have allocated times in the Health and Physical Education and English learning areas. Students in Years 11 and 12 will be involved in termly initiatives, assemblies, retreats and House activities which address topics relevant to senior students, including goal setting, time management and safer socialising.</p>
              <p class="mt-2">The student leadership program and the House Tutor Group system complement and support the implementation of the Yumi Strong program.</p>
              <p class="mt-2">The Yumi Strong program incorporates a range of learning opportunities that foster the development of understanding around real issues students encounter as they grow from young people in the primary school into young adults of the secondary school. The Yumi Strong program also incorporates the use of critical thinking, which aims to support and strengthen student cognitive capacity. Student thinking is valued, ideas and perceptions are challenged, and questioning is encouraged. Students are empowered to make informed decisions about the choices they make each day.</p>
              <p class="mt-2">Yumi Strong draws on a range of evidence-based resources and programs including Peer Support Program , Resilience, Rights and Respectful Relationships, Love Bites and Bounce Back!, as well as from organisations including Beyond Blue, headspace and Reach Out. Resources and programs developed in Vanuatu and the Pacific region will be sought and incorporated where available.</p> 
            </dd>
          </div>
        </dl>
      </div>

    </div>
  </template>
  <script setup>
  </script>
