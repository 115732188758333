import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://api-ap-southeast-2.wonde.com/v1.0',
    headers: {
      Authorization: `Bearer ${process.env.VUE_APP_WONDE_API_KEY}`
    }
  })

  // Add a request interceptor
apiClient.interceptors.request.use(function (config) {
  // Log the full request before it's sent
  //console.log(`Sending Request to ${config.url}`);
  //console.log('Request Headers:', config.headers);
  // You can log more details here if needed
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
  
  async function fetchAllPages(url, allData = []) {
    //console.log(`Fetching URL: ${url}`); // Log the URL being fetched
    const response = await apiClient.get(url);
    const data = response.data.data;
    allData = allData.concat(data);
  
    //console.log(`Fetched ${data.length} items`); // Log the number of items fetched
  
    if (response.data.meta && response.data.meta.pagination.more) {
      const nextPageUrl = response.data.meta.pagination.next;
      //console.log(`There's more data. Next page URL: ${nextPageUrl}`); // Log the next page URL
      return fetchAllPages(nextPageUrl, allData);
    } else {
      console.log('No more data. Final dataset size:', allData.length); // Log the final size of allData
      return allData;
    }
  }

  // Method to fetch all contacts for a given school
async function fetchAllContacts(schoolId) {
  console.log(`Fetching contacts for school ID: ${schoolId}`); // Log the school ID being queried
  const initialUrl = `/schools/${schoolId}/contacts?per_page=50`; // Adjusted URL for contacts
  const contacts = await fetchAllPages(initialUrl);
  console.log(`Total contacts fetched: ${contacts.length}`); // Log the total number of contacts fetched
  return { data: contacts };
}

// Method to fetch details for a specific contact
function getContactDetails(contactId) {
  console.log(`Fetching details for contact ID: ${contactId}`); // Log the contact ID being queried
  return apiClient.get(`/schools/{school_id}/contacts/${contactId}`);
}
  
  export default {
    getSchools() {
      console.log('Fetching schools'); // Log when fetching schools
      return apiClient.get('/schools?offset=0')
    },
    async getStudents(schoolId) {
      console.log(`Fetching students for school ID: ${schoolId}`); // Log the school ID being queried
      const initialUrl = `/schools/${schoolId}/students?per_page=50&extra_ids=true`; // Modified URL
      const students = await fetchAllPages(initialUrl);
      console.log(`Total students fetched: ${students.length}`); // Log the total number of students fetched
      return { data: students };
    },
    getStudentContactDetails(studentId) {
      console.log(`Fetching contact details for student ID: ${studentId}`); // Log the student ID being queried
      return apiClient.get(`/students/${studentId}/contactDetails`)
    },
    fetchAllContacts, // Export the new method for fetching all contacts
    getContactDetails, // Export the new method for fetching specific contact
  }