<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-2xl py-2">
      <a href="/billing/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Dashboard <span aria-hidden="true">→</span></a>
    </div>
    <div>

    <div class="sm:flex sm:items-center">

      <div class="sm:flex-auto text-left">
        <h1 class="text-base mt-12 font-semibold leading-6 text-gray-900">List of all PVIS students</h1>
        <p class="mt-2 text-sm text-gray-700">By Default this list all studients, use filtering option on the right to filter </p>

        <select v-model="selectedTerm" class="mt-2 form-select block w-1/4">
          <option value="1">Term 1</option>
          <option value="2">Term 2</option>
          <option value="3">Term 3</option>
          <option value="4">Term 4</option>
          <option value="full">Full Year</option>
        </select>
      </div>

      <div class="sm:flex-auto text-right ml-auto">
        <h1 class="text-base mt-12 font-semibold leading-6 text-gray-900">Filter</h1>
        <p class="mt-2 text-sm text-gray-700">Filter the table </p>

        <select v-model="selectedYearLevel" class="mt-2 form-select block w-1/4 ml-auto">
          <option value="ALL">All</option>
          <option value="ELP">ELP</option>
          <option value="PRE">PRE</option>
          <option value="FND">FND</option>
          <option value="Year 1">Year 1</option>
          <option value="Year 2">Year 2</option>
          <option value="Year 3">Year 3</option>
          <option value="Year 4">Year 4</option>
          <option value="Year 5">Year 5</option>
          <option value="Year 6">Year 6</option>
          <option value="Year 7">Year 7</option>
          <option value="Year 8">Year 8</option>
          <option value="Year 9">Year 9</option>
          <option value="Year 10">Year 10</option>
          <option value="Year 11">Year 11</option>
          <option value="Year 12">Year 12</option>
        </select>
      </div>

      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">

      </div>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <th scope="col" class="whitespace-normal break-words py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"><div class="w-20 truncate">Legal Name</div></th>
              <th scope="col" class="whitespace-normal break-words py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Family Code</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Hero ID</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">First Attendance</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900 w-16">Years at PVIS</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Level</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Primary Citizenship</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Fee Components</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                {{ selectedTerm === 'full' ? 'Full Year' : `Term ${selectedTerm}` }} Fees
              </th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Discount</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Total {{ selectedTerm === 'full' ? 'Full Year' : `Term ${selectedTerm}` }} Fees</th>

            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="student in filteredStudents" :key="student._id">
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0"><div @click="openDialog(student)" class="text-blue-500 w-40 truncate underline cursor-pointer">{{ student.firstNameLegal }} {{ student.lastNameLegal }}</div></td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ student.familyCode }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                <span :class="{
                'bg-purple-50 text-purple-700 ring-purple-600 text-center w-36': status(student.debenturesCorp, student.staffChild, student.bursary) === 'Partners in Education',
                'bg-yellow-50 text-yellow-700 ring-yellow-600 text-center w-36': status(student.debenturesCorp, student.staffChild, student.bursary) === 'Educators\' Family',
                'bg-yellow-50 text-orange-700 ring-orange-600 text-center w-36': status(student.debenturesCorp, student.staffChild, student.bursary) === 'Bursary',
                'bg-green-50 text-green-700 ring-green-600 text-center w-36': status(student.debenturesCorp, student.staffChild, student.bursary, student.firstAttendanceDate) === 'Regular',
                'bg-blue-50 text-blue-700 ring-blue-600 text-center w-36': status(student.debenturesCorp, student.staffChild, student.bursary, student.firstAttendanceDate) === 'Long Term',
              }" class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">
              {{ status(student.debenturesCorp, student.staffChild, student.bursary, student.firstAttendanceDate) }}
            </span>
              </td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ student.heroId }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0 w-8">{{ student.firstAttendanceDate ? formatDate(student.firstAttendanceDate) : '' }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ calculateCompletedYears(student.firstAttendanceDate) }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ student.currentYear }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0 w-36">{{ student.primaryCitizenship }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ student.feeComponent }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ formatNumber(calculateFee(student.currentYear, student.feeComponent, selectedTerm)) }} VT</td>
              
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ formatNumber(calculateDiscount(student).discountValue) }} VT</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0"> {{ formatNumber(calculateTotal(student, selectedTerm)) }} VT</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  </div>
<!-- MODAL EDIT STUDENT -->
<TransitionRoot as="template" :show="isOpen">
  <Dialog as="div" class="relative z-10" :open="isOpen" @close="isOpen = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle v-if="selectedStudent" class="text-base font-semibold leading-6 text-gray-900">{{ selectedStudent.firstNameLegal }} {{ selectedStudent.lastNameLegal }}</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="isOpen = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <!-- Your content -->
                    <template v-if="selectedStudent">
                      <form @submit.prevent="saveData">
                        <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label class="block text-sm font-medium text-gray-700">
                        First Name
                        <input v-model="selectedStudent.firstNameLegal" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Middle Names
                        <input v-model="selectedStudent.middleNames" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Last Name
                        <input v-model="selectedStudent.lastNameLegal" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Hero ID
                        <input v-model="selectedStudent.heroId" type="number" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        First Attendance Date
                        <input v-model="formattedFirstAttendanceDate" type="date" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Current Year
                        <input v-model="selectedStudent.currentYear" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">
                          Fee Component
                          <select v-model="selectedStudent.feeComponent" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <option value="full5days">Full ELP Program (5days)</option>
                            <option value="mornings5days">All Mornings (5days)</option>
                            <option value="mornings3days">Three Mornings (3days)</option>
                            <option value="littleExplorer3days">Little Explorers (3days)</option>
                            <option value="SS1">Sace Stage 1</option>
                            <option value="SS2">Sace Stage 2</option>
                          </select>
                        </label>
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">
                          Status
                          <div class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <div>
                              <input type="checkbox" v-model="selectedStudent.debenturesCorp" id="debenturesCorp" />
                              <label for="debenturesCorp" class="ml-2">Debentures or Corporate</label>
                            </div>
                            <div>
                              <input type="checkbox" v-model="selectedStudent.staffChild" id="staffChild" />
                              <label for="staffChild" class="ml-2">Child of Staff</label>
                            </div>
                            <div>
                              <input type="checkbox" v-model="selectedStudent.bursary" id="bursary" />
                              <label for="bursary" class="ml-2">Bursary</label>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Family Code
                        <input v-model="selectedStudent.familyCode" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Primary Citizenship
                        <input v-model="selectedStudent.primaryCitizenship" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 First Name
                        <input v-model="selectedStudent.contact1FirstName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 Last Name
                        <input v-model="selectedStudent.contact1LastName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 Email
                        <input v-model="selectedStudent.contact1Email" type="email" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 First Name
                        <input v-model="selectedStudent.contact2FirstName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 Last Name
                        <input v-model="selectedStudent.contact2LastName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 Email
                        <input v-model="selectedStudent.contact2Email" type="email" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      </div>
                      
                    </form>
                  </template>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" @click="closeDialog">Cancel</button>
<button type="submit" class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500" @click="saveData">Save</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import axios from 'axios';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { onMounted, ref, computed, defineExpose } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline'

let selectedStudent = ref(null);
let isOpen = ref(false);

const openDialog = (student) => {
  selectedStudent.value = student;
  isOpen.value = true;
};

const students = ref([]);
const selectedTerm = ref('1');
const selectedYearLevel = ref('ALL');
const invoices = ref([]);

onMounted(async () => {
  try {
    const response = await axios.get('/api/getStudents');
    students.value = response.data;
    console.log('Received students data successfully');
  } catch (error) {
    console.error('Error fetching students:', error);
  }
  try {
    const response = await axios.get('/api/getInvoices');
    invoices.value = response.data;
    console.log('Received invoices data successfully');
  } catch (error) {
    console.error('Error fetching invoices:', error);
  }
});

let filteredStudents = computed(() => {
  if (selectedYearLevel.value === 'ALL') {
    return students.value;
  } else {
    return students.value.filter(student => student.currentYear === selectedYearLevel.value);
  }
});

const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return ''; // return an empty string if dateString is not a valid date
  }
  return date.toISOString().split('T')[0]; // returns date in YYYY-MM-DD format
};

const saveData = async () => {
  try {
    await axios.put('/api/updateStudent', {
      id: selectedStudent.value._id,
      studentData: selectedStudent.value
    });
    isOpen.value = false; // Close the slide-over panel after saving
  } catch (error) {
    console.error('Error updating student:', error);
  }
};

const closeDialog = () => {
  isOpen.value = false; // Close the slide-over panel
};

// Function to format date
const formatDateToYYYYMMDD = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

// Computed property for formatted date
const formattedFirstAttendanceDate = computed({
  get: () => formatDateToYYYYMMDD(selectedStudent.value.firstAttendanceDate),
  set: (value) => { selectedStudent.value.firstAttendanceDate = new Date(value); }
});

const calculateCompletedYears = (firstAttendanceDate) => {
  const startDate = new Date(firstAttendanceDate);
  const endDate = new Date();

  if (isNaN(startDate.getTime())) {
    return ''; // return an empty string if firstAttendanceDate is not a valid date
  }

  let years = endDate.getFullYear() - startDate.getFullYear();

  // Subtract a year if the current date is before the attendance date in the current year
  if (endDate.getMonth() < startDate.getMonth() || 
      (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())) {
    years--;
  }

  return years < 0 ? 0 : years; // returns the number of fully completed years, or 0 if the first attendance date is in the future
};

const TermFee = (level) => {
  const fees = {
    'ELP': 149427,
    'PRE': 149427,
    'FND': 208472,
    'Year 1': 208472,
    'Year 2': 208472,
    'Year 3': 208472,
    'Year 4': 208472,
    'Year 5': 208472,
    'Year 6': 208472,
    'Year 7': 225982,
    'Year 8': 225982,
    'Year 9': 225982,
    'Year 10': 225982,
    'Year 11': 230411,
    'Year 12': 230411,
  };

  return fees[level] || 0;
};

const ressourceLevy = (level) => {
  const fees = {
    'ELP': 0,
    'PRE': 0,
    'FND': 22500,
    'Year 1': 22500,
    'Year 2': 22500,
    'Year 3': 22500,
    'Year 4': 22500,
    'Year 5': 22500,
    'Year 6': 22500,
    'Year 7': 27500,
    'Year 8': 27500,
    'Year 9': 27500,
    'Year 10': 27500,
    'Year 11': 42500,
    'Year 12': 52500,
  };

  return fees[level] || 0;
};

const feeComponents = {
  'full5days': 149427,
  'mornings5days': 105987,
  'mornings5daysLE': 136887,
  'mornings3days': 78589,
  'mornings3daysLE': 109489,
  'SS1': 96705,
  'SS2': 159600
};

const calculateFee = (level, feeComponent) => {
  let fee;

  if (['full5days', 'mornings5days', 'mornings5daysLE', 'mornings3days', 'mornings3daysLE'].includes(feeComponent)) {
    fee = feeComponents[feeComponent] || 0;
  } else {
    fee = TermFee(level);
  }
  return fee;
};

const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const status = (debenturesCorp, staffChild, bursary, firstAttendanceDate) => {
  const years = calculateCompletedYears(firstAttendanceDate);

  if (debenturesCorp) {
    return 'Partners in Education';
  } else if (staffChild) {
    return 'Educators\' Family';
  } else if (bursary) {
    return 'Bursary';
  } else if (years > 2) {
    return 'Long Term';
  } else {
    return 'Regular';
  }
};

const calculateDiscount = (student) => {
  let discountName = '';
  let discountValue = 0;

  if (student.currentYear === 'ELP' || student.currentYear === 'PRE') {
    return { discountName, discountValue }; // Return early with 0 discount for ELP or PRE
  }

  if (student.debenturesCorp) {
    discountName = 'Partners in Education';
  } else {
    discountName = 'Discount A';
    discountValue = 33000;
  }

  if ((student.primaryCitizenship === 'Vanuatu' || student.staffChild) && !(student.currentYear === 'ELP' || student.currentYear === 'PRE')) {
    discountName = 'Discount B';
    discountValue = 60000;
  }

  return { discountName, discountValue };
};


 const calculateTotal = (student, selectedTerm) => {
  let fee = calculateFee(student.currentYear, student.feeComponent, selectedTerm);
  const { discountValue } = calculateDiscount(student);

  let total = fee - discountValue;

  if (student.bursary || student.staffChild) {
    total *= 0.5; // 50% additional discount
  }

  // Add resource levy and SS1, SS2 fees after applying the discount
  if (selectedTerm === '1') {
    total += ressourceLevy(student.currentYear);
  }
  if (['SS1', 'SS2'].includes(student.feeComponent)) {
    total += feeComponents[student.feeComponent] || 0;
  }

  return total;
};



defineExpose({
  status,

});

</script>
