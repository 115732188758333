<template>
  <div class="relative isolate px-6 pt-14 lg:px-8">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>

      <div class="mx-auto max-w-2xl py-16 sm:py-24 lg:py-28">
        <div class="mb-12">
        <button @click="connectToXero" class="btn btn-primary  bg-blue-100 text-blue-600 ring-blue-600 inline-flex items-center rounded-md px-3 py-2 text-sm font-medium ring-1 ring-inset">Connect to Xero</button>
        <div v-if="isConnected" class="mt-2 ml-4 bg-green-100 text-green-600 ring-green-600 inline-flex items-center rounded-md px-3 py-2 text-sm font-medium ring-1 ring-inset">Connected to Xero</div>
        </div>


        <div class="text-center">
          <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">PVIS Billing Dashboard</h1>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a href="/billing/complete" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Generate Invoice</a>
            <a href="/billing/wonde-sync" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sync Student</a>
            <a href="/billing/family" class="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">Edit per Familly</a>
            <a href="/billing/year" class="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">Edit per Year Level</a>
            <a href="/billing/contact" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Edit Contacts</a>

          </div>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a href="/billing/settings" class="text-sm font-semibold leading-6 text-gray-900">Settings <span aria-hidden="true">→</span></a>
            <a href="#" @click.prevent="logout" class="text-sm font-semibold leading-6 text-gray-900">Log Out <span aria-hidden="true">→</span></a>
          </div>
      </div>
      </div>
      <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
        <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>

      <!-- Stats -->
      <div>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Total Number of Students</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ students ? students.length : 'Loading...' }}</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Total Number of EEC (ELP and PRE)</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ EECCount }}</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Total Number of Junior School (FND to Y4)</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ juniorSchoolCount }}</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Total Number of Middle School (Y5 to Y8)</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ middleSchoolCount }}</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Total Number of Senior School (Y9 to Y12)</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ seniorSchoolCount }}</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">% of Students with Regular Discount (Discount A) (WRONG - to be updated)</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900"> {{ discountAStudents.toFixed(2) }}%</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">% of Students 3 years Discount (Discount B) (WRONG - to be updated)</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ percentageOverThreeYears.toFixed(2) }}%</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">% of Students with any discount (WRONG - to be updated)</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ anyDiscount.toFixed(2) }}%</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Total of {{ selectedTerm === 'full' ? 'Full Year' : `Term ${selectedTerm}` }} Fees (WRONG - to be updated)</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ totalOfTotalTerm1Fee }}</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Total Discounts for {{ selectedTerm === 'full' ? 'Full Year' : `Term ${selectedTerm}` }} (WRONG - to be updated)</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ totalDiscounts }}</dd>
      </div>
      <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
        <dt class="truncate text-sm font-medium text-gray-500">Total Discounts for {{ selectedTerm === 'full' ? 'Full Year' : `Term ${selectedTerm}` }} (WRONG - to be updated)</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ totalDiscounts }}</dd>
      </div>
    </dl>

    <p class="mt-6 text-lg leading-8 text-gray-600">Please contact <a href="mailto:pierre-luc@ajc-vanuatu.com" class="text-indigo-600 hover:text-indigo-500">pierre-luc@ajc-vanuatu.com</a> for any errors or suggestions</p>

  </div>

  {{ totalDiscountAGiven }}
  {{ totalDiscountBGiven }}
  


    </div>
</template>

<script setup>
import { onUnmounted, onMounted, ref, computed } from 'vue';
import { auth } from '../firebase' // adjust the path according to your project structure
import { useRouter } from 'vue-router'
import axios from 'axios';


const students = ref([]);
const router = useRouter()
const isMounted = ref(true)
const selectedTerm = ref('1'); // Default term



const clientId = process.env.VUE_APP_XERO_CLIENTID; // Replace with your actual client ID
const redirectUri = 'https://pvis.edu.vu/api/auth'; // Replace with your actual redirect URI
const scope = 'openid profile email accounting.contacts accounting.transactions accounting.settings accounting.attachments'; // Replace with the scopes you need
let state = '123'; // Replace with your actual state
const isConnected = ref(false); // Add this line

const connectToXero = () => {
  const authUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  
  console.log(authUrl); // Log the URL

  window.location.href = authUrl; // Open the URL in a new window or tab

};

const checkConnection = async () => {
  try {
    const response = await axios.get('/api/checkConnection');
    isConnected.value = response.data.isConnected;
  } catch (error) {
    console.error('Error checking connection:', error);
  }
};

onMounted(async () => {
  try {
    const response = await axios.get('/api/getStudents');
    students.value = response.data;
    console.log('Received students data successfully');
  } catch (error) {
    console.error('Error fetching students:', error);
  }
  checkConnection();
});

onUnmounted(() => {
  isMounted.value = false
})

const TermFee = (level) => {
  const fees = {
    'ELP': 149427,
    'PRE': 149427,
    'FND': 208472,
    'Year 1': 208472,
    'Year 2': 208472,
    'Year 3': 208472,
    'Year 4': 208472,
    'Year 5': 208472,
    'Year 6': 208472,
    'Year 7': 225982,
    'Year 8': 225982,
    'Year 9': 225982,
    'Year 10': 225982,
    'Year 11': 230411,
    'Year 12': 230411,
  };

  return fees[level] || 0;
};

const ressourceLevy = (level) => {
  const fees = {
    'ELP': 21500,
    'PRE': 21500,
    'FND': 22500,
    'Year 1': 22500,
    'Year 2': 22500,
    'Year 3': 22500,
    'Year 4': 22500,
    'Year 5': 22500,
    'Year 6': 22500,
    'Year 7': 27500,
    'Year 8': 27500,
    'Year 9': 27500,
    'Year 10': 27500,
    'Year 11': 42500,
    'Year 12': 52500,
  };

  return fees[level] || 0;
};

const feeComponents = {
  'full5days': 149427,
  'mornings5days': 105987,
  'mornings5daysLE': 136887,
  'mornings3days': 78589,
  'mornings3daysLE': 109489,
  'SS1': 96705,
  'SS2': 159600
};

const logout = async () => {
  await auth.signOut()
  if (isMounted.value) {
    router.push('/billing/login') // replace with your login route
  }
}

const calculateDiscount = (student) => {
  let discountName = '';
  let discountValue = 0;

  if (student.debenturesCorp) {
    discountName = 'Partners in Education';
  } else {
    discountName = 'Discount A';
    discountValue = 33000;
  }

  if (student.primaryCitizenship === 'Vanuatu' || calculateCompletedYears(student.firstAttendanceDate) > 2) {
    discountName = 'Discount B';
    discountValue = 60000;
  }

  return { discountName, discountValue };
};

// Computed property to calculate the total amount of Discount A given
const totalDiscountAGiven = computed(() => {
  // Filter students who received Discount A
  const discountAStudents = students.value.filter(student => {
    const { discountName, discountValue } = calculateDiscount(student);
    return discountName === 'Discount A' && discountValue === 33000;
  });

  // Calculate the total amount of Discount A given
  const total = discountAStudents.length * 33000; // 33,000vt is the value of Discount A
  return total;
});

// Computed property to calculate the total amount of Discount A given
const totalDiscountBGiven = computed(() => {
  // Filter students who received Discount A
  const discountAStudents = students.value.filter(student => {
    const { discountName, discountValue } = calculateDiscount(student);
    return discountName === 'Discount B' && discountValue === 60000;
  });

  // Calculate the total amount of Discount A given
  const total = discountAStudents.length * 60000; // 60,000vt is the value of Discount B
  return total;
});


const calculateTotal = (student, selectedTerm) => {
  let fee = calculateFee(student.currentYear, student.feeComponent, selectedTerm);
  const { discountValue } = calculateDiscount(student);

  let total = fee - discountValue;

  if (student.bursary || student.staffChild) {
    total *= 0.5; // 50% additional discount
  }

  // Add resource levy and SS1, SS2 fees after applying the discount
  if (selectedTerm === '1') {
    total += ressourceLevy(student.currentYear);
  }
  if (['SS1', 'SS2'].includes(student.feeComponent)) {
    total += feeComponents[student.feeComponent] || 0;
  }

  return total;
};

const calculateCompletedYears = (firstAttendanceDate) => {
  const startDate = new Date(firstAttendanceDate);
  const endDate = new Date();

  if (isNaN(startDate.getTime())) {
    return ''; // return an empty string if firstAttendanceDate is not a valid date
  }

  let years = endDate.getFullYear() - startDate.getFullYear();

  // Subtract a year if the current date is before the attendance date in the current year
  if (endDate.getMonth() < startDate.getMonth() || 
      (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())) {
    years--;
  }

  return years < 0 ? 0 : years; // returns the number of fully completed years, or 0 if the first attendance date is in the future
};


const calculateFee = (level, feeComponent) => {
  let fee;

  if (['full5days', 'mornings5days', 'mornings5daysLE', 'mornings3days', 'mornings3daysLE'].includes(feeComponent)) {
    fee = feeComponents[feeComponent] || 0;
  } else {
    fee = TermFee(level);
  }
  return fee;
};

// Calculations
const discountAStudents = computed(() => {
  const count = students.value.filter(student => calculateDiscount(student).discountName === 'Discount A').length;
  return (count / students.value.length) * 100;
});

const percentageOverThreeYears = computed(() => {
  const count = students.value.filter(student => calculateDiscount(student).discountName === 'Discount B').length;
  return (count / students.value.length) * 100;
});

const anyDiscount = computed(() => {
  const count = students.value.filter(student => calculateDiscount(student).discountValue > 0).length;
  return (count / students.value.length) * 100;
});

const totalOfTotalTerm1Fee = computed(() => {
  return students.value.reduce((total, student) => total + calculateTotal(student, '1'), 0);
});

const totalDiscounts = computed(() => {
  return students.value.reduce((total, student) => total + calculateDiscount(student).discountValue, 0);
});


const EECCount = computed(() => {
  return students.value.filter(student => ['ELP', 'PRE'].includes(student.currentYear)).length;
});

const juniorSchoolCount = computed(() => {
  return students.value.filter(student => ['FND', 'Year 1', 'Year 2', 'Year 3', 'Year 4'].includes(student.currentYear)).length;
});

const middleSchoolCount = computed(() => {
  return students.value.filter(student => ['Year 5', 'Year 6', 'Year 7', 'Year 8'].includes(student.currentYear)).length;
});

const seniorSchoolCount = computed(() => {
  return students.value.filter(student => ['Year 9', 'Year 10', 'Year 11', 'Year 12'].includes(student.currentYear)).length;
});

</script>