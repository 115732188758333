// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import { createHead } from '@vueuse/head';
import './assets/css/main.css';
import getRouter from './router'; // Import the router as a function
import { inject } from '@vercel/analytics';

console.log('Starting application...');

async function bootstrap() {
  const router = await getRouter(); // Wait for the router to be ready
  const app = createApp(App);
  const head = createHead();

  app.use(router);
  inject();

  app.use(head);
  app.mount('#app');
}

bootstrap();