<template>
  <div class="bg-white mb-20">
    <div class="mx-auto mt-40 max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:text-center">
        <h2 class="text-base font-semibold leading-7 text-indigo-600">Something for Everyone</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">After School Activities</p>
        <p class="mt-6 text-lg leading-8 text-gray-600">The After School Activities programme gives primary aged students the chance to participate in a wide variety of extra-curricular activities. These classes take place at school from 2pm-3pm, Mondays-Thursday. They begin in week 3 and run through week 8. Students will bring a form home during the first week of each term to make their selections for the term.</p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          <div v-for="feature in activities" :key="feature.name" class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">
              {{ feature.name }}
            </dt>
            <dd class="mt-2 text-base leading-7 text-gray-600">{{ feature.description }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>

const activities = [
{
    name: 'Judo',
    description: 'Develop discipline and strength with our Judo class, where students learn the art of this Olympic sport in a safe and respectful environment.',
    icon: 'JudoIcon',
  },
  {
    name: 'Science Fun',
    description: 'Explore the wonders of science through exciting experiments and hands-on activities that make learning fun and engaging.',
    icon: 'ScienceIcon',
  },
  {
    name: 'Computer Club',
    description: 'Join the future tech leaders and create, code, and collaborate on projects that spark digital creativity and innovation.',
    icon: 'ComputerIcon',
  },
  {
    name: 'Netball',
    description: 'Shoot, pass, and score with netball, a team sport that enhances coordination, teamwork, and physical fitness.',
    icon: 'NetballIcon',
  },
  {
    name: 'Weaving',
    description: 'Weave creativity into your day with our weaving class, blending traditional techniques and artistic expression.',
    icon: 'WeavingIcon',
  },
  {
    name: 'Bislama',
    description: 'Dive into the local language with Bislama classes, enriching communication skills and cultural understanding.',
    icon: 'LanguageIcon',
  },
  {
    name: 'Multisport',
    description: 'Get a taste of various sports, improving overall athleticism and finding your passion in physical activity.',
    icon: 'MultisportIcon',
  },
  {
    name: 'Dodgeball',
    description: 'Dodge, throw, and catch your way to reflexive excellence in our energetic and thrilling dodgeball games.',
    icon: 'DodgeballIcon',
  },
  {
    name: 'Cricket',
    description: 'Learn the classic game of cricket, practicing batting, bowling, and fielding skills in a team-focused environment.',
    icon: 'CricketIcon',
  },
  {
    name: 'Futsal',
    description: 'Experience the fast-paced world of futsal, a soccer variant that sharpens your quick thinking and footwork.',
    icon: 'FutsalIcon',
  },
  {
    name: 'Drama',
    description: 'Unleash your inner actor with drama classes, fostering self-expression, confidence, and performance skills.',
    icon: 'DramaIcon',
  },
  {
    name: 'Maths Extension',
    description: 'Challenge your numeracy skills with our Maths Extension, designed to stimulate and extend your mathematical understanding.',
    icon: 'MathsIcon',
  },
  {
    name: 'Hip Hop',
    description: 'Step into the world of hip hop with energetic dance routines that promote rhythm, expression, and fitness.',
    icon: 'HipHopIcon',
  },
  {
    name: 'Ballet',
    description: 'Embrace the grace of ballet, learning the foundational techniques and movements that have captivated audiences for centuries.',
    icon: 'BalletIcon',
  },
  {
    name: 'Rugby',
    description: 'Rugby brings together speed, strategy, and strength, teaching teamwork and resilience on the field.',
    icon: 'RugbyIcon',
  },
  {
    name: 'Table Tennis',
    description: 'Sharpen your reflexes and enjoy the strategic game of table tennis, a sport that offers both fun and competitive play.',
    icon: 'TableTennisIcon',
  },
  {
    name: 'Field Games',
    description: 'From tag to capture the flag, our field games offer a mix of traditional and innovative outdoor fun for all fitness levels.',
    icon: 'FieldGamesIcon',
  },
  {
    name: 'Creative Thinkers',
    description: 'Stimulate innovation and originality with activities that inspire students to think outside the box and develop problem-solving skills.',
    icon: 'CreativeThinkersIcon',
  },
  {
    name: 'Arts and Crafts',
    description: 'Express your artistic side with our Arts and Crafts, where imagination meets the joy of creating with your hands.',
    icon: 'ArtsCraftsIcon',
  },
]
</script>