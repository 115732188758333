<template>
  <div class="bg-white px-6 pb-32 lg:pb-8">
    <div class="mx-auto mt-40 max-w-3xl text-base leading-7 text-gray-700">
      <p class="text-base font-semibold leading-7 text-indigo-600">Port Vila International School</p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Vision, Mission and Values</h1>
      <h2 class="mt-12 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Vision</h2>
      <p class="">Port Vila International School is the leading independent school in Vanuatu.</p>
      <p class="mt-4 ">
We serve education in our country and help to build our national vision to become STABLE, SUSTAINABLE AND PROSPEROUS by being an exceptional learning community, that nurtures exceptional individuals 
</p>
      <div class="mt-6">
        <h2 class="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Mission</h2>
        <p class="">To promote the learning and wellbeing of every student in a diverse, caring and welcoming community so that they will thrive in Vanuatu and beyond as the creators and advocates for their future.</p>

          <p class="mt-4">To inspire belief in self and others and help us all learn what is possible during a wonderful adventure of exciting possibilities.</p>

            <p class="mt-4" >To seek opportunities and create innovative solutions with a social and environmental conscience.
</p>
        
      </div>
      <div class="mt-6">
        <h2 class="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Values</h2>
        <ul>
          <li class="mt-4"><span class="font-bold">EXCELLENCE</span><br/>that inspires us to rise to the challenge of delivering our best for each other, our school, Vanuatu and the world.</li>
          <li class="mt-2"><span class="font-bold">RESPECT</span><br/>that brings us together to engage with honour and celebrate our different perspectives and contributions.</li>
          <li class="mt-2"><span class="font-bold">CURIOSITY</span><br/>that empowers our minds to wonder, to think for ourselves and to seek improvement in all we do throughout our lives.</li>
          <li class="mt-2"><span class="font-bold">COURAGE</span><br/>that helps us to lead the way forward with boldness and create solutions that will leave a positive legacy.</li>
          <li class="mt-2"><span class="font-bold">EQUITY</span><br/>that enables and affirms that we will not be limited by gender, ethnicity or class and that we treat all in our community with fairness.</li>
        </ul>

      </div>
      <p class="mt-12 font-bold tracking-tight text-gray-900">TODAY’S LEARNING FOR TOMORROW’S WORLD – LET’S CREATE TOMORROW TOGETHER</p>
    </div>
  </div>
</template>

<script setup>


</script>