
<template>
    <div class="bg-white pb-24 sm:pb-32">
      <div class="mx-auto mt-40 max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <h2 class="text-base font-semibold leading-7 text-custom-blue">Shaping Tomorrow</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Strategic Plan – 2022 and beyond</p>
          <p class="mt-6 text-lg leading-8 text-gray-600">At the heart of our new strategic plan lies a deepened commitment to serving Vanuatu, aligning with the nation's vision for a stable, sustainable, and prosperous future by 2030. We understand that the learning landscape is ever-evolving, calling for education that is not just robust but courageous, bold, and innovative—traits necessary for the challenges of tomorrow.</p>

            <p class="mt-6 text-lg leading-8 text-gray-600">Reflecting on our 30th anniversary in 2022, PVIS embraced a renewed sense of identity through 'The PVIS Way.' This ethos, which captures the essence of our approach to teaching, learning, caring, and leading, has been shaped by the collective contributions of our dedicated community across three decades.</p>

            <p class="mt-6 text-lg leading-8 text-gray-600">Our revitalized values resonate with 'The PVIS Way,' embracing excellence, respect, curiosity, courage, and equality. These are not just words but the principles that guide our actions and intentions, shaping the unique educational experience at PVIS.</p>

            <p class="mt-6 mb-12 text-lg leading-8 text-gray-600">Looking forward, we pledge to pursue opportunities that carry a strong social and environmental conscience, mirroring the aspirations of our student body. We are dedicated to fostering a learning environment where innovation meets conscientiousness, creating a future that our students can be proud to shape.</p>

            <a href="/PVIS-Strategic-Plan-2022-web.pdf" download>
            <button type="button" class="rounded-md bg-custom-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:custom-blue-pale focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-custom-blue">
                Download our Strategic Plan [PDF]
            </button>
</a>
        </div>

    <div class="bg-white mt-16">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="text-center text-lg font-semibold leading-8 text-gray-900">Created in partnership with</h2>
        <div class="mx-auto mt-10 grid max-w-lg grid-cols-3 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/partners/adaptive.webp" alt="Adaptive" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/partners/ahisa.webp" alt="Ahisa" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/partners/breadfruit.webp" alt="Breadfruit" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/partners/curric.webp" alt="Curric" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/partners/nation.webp" alt="Nation" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/partners/sace.webp" alt="Sace" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/partners/tomorrow.webp" alt="Tomorrow" width="158" height="48" />
          <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" src="@/assets/partners/vanuatu.png" alt="Vanuatu" width="158" height="48" />
        </div>
      </div>
    </div>
    </div>
    </div>
  </template>
  <script setup>

  </script>
