<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl px-6 py-16 mt-24 sm:py-24 lg:px-8">
      <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">Curriculum Overview at PVIS</h2>
      <p class="mt-6 text-base leading-7 text-gray-600">Our curriculum aims to promote the learning and wellbeing of every student in a diverse, caring and welcoming community so that they will thrive in Vanuatu and beyond as the creators and advocates for their future.</p>
      <p class="mt-6 text-base font-semibold leading-7 text-gray-900">Today’s Learning for Tomorrow’s World </p>
      <p class="text-base leading-7 text-gray-600">Our curriculum, aligned with the Australian Curriculum and Achievement Standards from Foundation to Year 10, is thoughtfully developed and adapted to our context reflecting our identity as an international community based in Vanuatu. Our Senior School follows the SACE International Program.</p>
      <p class="mt-6 text-base leading-7 text-gray-600">Our mission is clear: to set each student on a path to success. We place the pursuit of knowledge, skills, and character at the forefront of our educational approach, embracing every learner's potential. By supporting academic growth and wellbeing, we aim to empower our students, helping them thrive academically and personally.</p>

      <div class="mt-20">

        <div class="space-y-6 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
          <div> 
            <p class="text-base font-semibold leading-7 text-gray-900">Early Education Centre: Preschoool - Beginning Foundations</p>
            <p class="mt-2 text-base leading-7 text-gray-600">We have two-year preschool program guided by the Early Years Learning Framework (EYLF).</p>
            <p class="mt-2 text-base leading-7 text-gray-600">Early Learning Program (Junior Preschool). Our early learning program helps children build the social-emotional, language and thinking skills they need to be successful in school and life. Children are encouraged to explore, discover, experience, and above all have fun. The early learning program supports children’s connections between home and early learning. The approach in our early learning program is play-based learning; where children’s hance through a variety of experiences and activities, including music, paints, water, messy play, collage, sand, and many more. We respond to children’s learning both on an individual and group basis.</p>
            <p class="mt-2 text-base leading-7 text-gray-600">Preschool. Our Preschool program is a high-quality early learning program delivered in the year before a child starts primary school. It is a crucial year where children are equipped with the vital skills needed for school and life success. The Preschool program is a balanced curriculum, combining a proven mix of free play and structured learning that includes a strong focus on early literacy and numeracy, cognitive and behavioural resilience, and support social development and emotional regulation. This program supports children with the necessary skills to move into the next stage of their education. There is a strong focus on language-based activities that provide formal and informal opportunities for thinking, problem-solving and experimenting. We respond to children’s learning both on an individual and group basis.</p>
        </div>
        <div> 
            <p class="text-base font-semibold leading-7 text-gray-900">Junior School ( Primary) : Foundation to Year 4 - Building Blocks of Learning</p>
            <p class="mt-2 text-base leading-7 text-gray-600">From Foundation to Year 4, students begin their formal education journey with a sense of wonder and inquiry. Our curriculum is a framework upon which we build a more enriched, globally and locally informed educational experience. Structured Literacy is an integral part of our strategy, ensuring all students develop, master, and extend vital literacy skills and knowledge in a comprehensive, explicit, and engaging manner. Students engage in English, Mathematics, Inquiry (a mixture of Science, Humanities, and Social Sciences), HPE, The Arts, Design and Digital Technologies, French, and our unique wellbeing framework, Yumistrong. We continue to build on the  social-emotional, language and thinking skills they need to be successful in school and life. We respond to children’s learning both on an individual and group basis to help students achieve their best.</p>
        </div>
        <div> 
            <p class="text-base font-semibold leading-7 text-gray-900">Middle School (Upper Primary to Junior Secondary): Year 5 to Year 8 - Creating Pathways</p>
            <p class="mt-2 text-base leading-7 text-gray-600">In Middle School, the curriculum expands to meet the growing intellectual and social horizons of our students.  We maintain a strong focus on literacy and introduce Maths Pathways from Year 5, enabling personalised progression in mathematics. This period marks a significant transition towards more independent learning, where students are encouraged to take greater agency in their education. Building leadership and character becomes a key focus, empowering students to navigate their own learning paths with confidence and resilience. Students engage in English, Mathematics, Science, Humanities, HPE, The Arts, Design and Digital Technologies, French, and our wellbeing framework, Yumistrong. Students increase their use of digital technology in learning from Year 5 and move to a BYOD in Year 7.</p>
        </div>
        <div> 
            <p class="text-base font-semibold leading-7 text-gray-900">Senior School (Middle to Senior Secondary): Year 9 to Year 12 - Future Ready</p>
            <p class="mt-2 text-base leading-7 text-gray-600">As students enter the Senior School, they are offered elective subjects, allowing them to explore personal interests and begin to specialize, a long with core subjects English, Mathematics, Science and HPE. From Year 10, students can start the South Australian Certificate of Education (SACE) International, an internationally recognized qualification. Please see our SACE International page for more information.</p>
        </div>
        <div> 
            <p class="text-base font-semibold leading-7 text-gray-900">Assessment and Understanding Each Student</p>
            <p class="mt-2 text-base leading-7 text-gray-600">At PVIS, students' progress is constantly tracked through a comprehensive assessment and monitoring schedule. Informing teaching practice and ensuring each child achieves their personal best, the gathering of student data allows our teachers to make in-depth judgments about each child’s progress and individual needs. We use a range assessments tools to measure students' performance.</p>
            <p class="mt-2 text-base leading-7 text-gray-600">One tool the ACER PAT Adaptive (Australian Council of Education Research Progressive Achievement Test) an Australian based assessment conducted to assess student skills in the areas of Numeracy and Literacy. Typically conducted at enrolment and then annually. The assessment is used by teachers to know their students and support their learning.</p>
          </div>
          <div> 
            <p class="text-base font-semibold leading-7 text-gray-900">French</p>
            <p class="mt-2 text-base leading-7 text-gray-600">Recognizing the importance of linguistic diversity and cultural understanding, we offer French as a dedicated part of our curriculum, celebrating it as an official language of Vanuatu.</p>
          </div>
          <div> 
            <p class="text-base font-semibold leading-7 text-gray-900">Sport and Physical Education</p>
            <p class="mt-2 text-base leading-7 text-gray-600">Our Physical Education and sport programs aim to nurture physical fitness, cooperation, and leadership. With an emphasis on variety, fun, and a sense of achievement, our programs encourage sportsmanship and confidence in every student.</p>
          </div>
          <div> 
            <p class="text-base font-semibold leading-7 text-gray-900">Leadership</p>
            <p class="mt-2 text-base leading-7 text-gray-600">We are committed to fostering leadership and public speaking skills, ensuring our students are well-rounded and prepared to take on the world. We develop leadership through student leadership roles, public speaking opportunities, peer support, and student-run events.</p>
          </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>