<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-2xl py-2">
      <a href="/billing/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Dashboard <span aria-hidden="true">→</span></a>
    </div>

    <div>
    <button @click="connectToXero" class="btn btn-primary  bg-blue-100 text-blue-600 ring-blue-600 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">Connect to Xero</button>
    <div v-if="isConnected" class="mt-2 bg-green-100 text-green-600 ring-green-600 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">Connected to Xero</div>
    </div>

    <div class="sm:flex sm:items-center">

      <div class="sm:flex-auto text-left">
        <h1 class="text-base mt-12 font-semibold leading-6 text-gray-900">List of all PVIS students</h1>
        <p class="mt-2 text-sm text-gray-700">By Default this list all studients, use filtering option on the right to filter </p>

        <select v-model="selectedTerm" class="mt-2 form-select block w-1/4">
          <option value="1">Term 1</option>
          <option value="2">Term 2</option>
          <option value="3">Term 3</option>
          <option value="4">Term 4</option>
          <option value="full">Full Year</option>
        </select>
      </div>

      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button @click="generateInvoicesInBulk" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Generate Invoices in Bulk</button>
        <div v-if="isBulkLoading" class="text-xs mt-2">Processing...</div>
        <p class="text-xs mt-2">Proceess in group of 50, skip errors</p>
      </div>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <th scope="col" class="whitespace-normal break-words py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"><div class="w-20 truncate">Legal Name</div></th>
              <th scope="col" class="whitespace-normal break-words py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Family Code</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Hero ID</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">First Attendance</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900 w-16">Years at PVIS</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Level</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Citizenship</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Components</th>
              <th scope="col" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Discount</th>
              <!-- New sub-headers for each term -->
             

            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="student in studentsWithTotal" :key="student._id">
        <template v-if="student.isTotalRow">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td colspan="2" class="whitespace-normal break-words px-2 py-3.5 text-left text-sm font-semibold text-gray-900">{{ student.familyCode }}</td>


        </template>
              <template v-else>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0"><div @click="openDialog(student)" class="text-blue-500 w-40 truncate underline cursor-pointer">{{ student.firstNameLegal }} {{ student.lastNameLegal }}</div></td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ student.familyCode }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                <span :class="{
                'bg-purple-50 text-purple-700 ring-purple-600 text-center w-36': status(student.debenturesCorp, student.staffChild, student.bursary) === 'Partners in Education',
                'bg-yellow-50 text-yellow-700 ring-yellow-600 text-center w-36': status(student.debenturesCorp, student.staffChild, student.bursary) === 'Educators\' Family',
                'bg-yellow-50 text-orange-700 ring-orange-600 text-center w-36': status(student.debenturesCorp, student.staffChild, student.bursary) === 'Bursary',
                'bg-green-50 text-green-700 ring-green-600 text-center w-36': status(student.debenturesCorp, student.staffChild, student.bursary, student.firstAttendanceDate) === 'Regular',
                'bg-blue-50 text-blue-700 ring-blue-600 text-center w-36': status(student.debenturesCorp, student.staffChild, student.bursary, student.firstAttendanceDate) === 'Long Term',
              }" class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">
              {{ status(student.debenturesCorp, student.staffChild, student.bursary, student.firstAttendanceDate) }}
            </span>
              </td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ student.heroId }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0 w-8">{{ student.firstAttendanceDate ? formatDate(student.firstAttendanceDate) : '' }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ calculateCompletedYears(student.firstAttendanceDate) }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ student.currentYear }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0 w-36">{{ student.primaryCitizenship }}</td>
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ student.feeComponent }}</td>              
              <td class="whitespace-nowrap py-2 text-left pl-4 pr-3 text-sm text-gray-500 sm:pl-0"> {{ calculateDiscount(student).discountName }}</td>
            
              </template>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
<!-- MODAL EDIT STUDENT -->
<TransitionRoot as="template" :show="isOpen">
  <Dialog as="div" class="relative z-10" :open="isOpen" @close="isOpen = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle v-if="selectedStudent" class="text-base font-semibold leading-6 text-gray-900">{{ selectedStudent.firstNameLegal }} {{ selectedStudent.lastNameLegal }}</DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="isOpen = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <!-- Your content -->
                    <template v-if="selectedStudent">
                      <form @submit.prevent="updateStudent">
                        <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label class="block text-sm font-medium text-gray-700">
                        First Name
                        <input v-model="selectedStudent.firstNameLegal" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Middle Names
                        <input v-model="selectedStudent.middleNames" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Last Name
                        <input v-model="selectedStudent.lastNameLegal" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Hero ID
                        <input v-model="selectedStudent.heroId" type="number" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        First Attendance Date
                        <input v-model="formattedFirstAttendanceDate" type="date" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Current Year
                        <input v-model="selectedStudent.currentYear" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">
                          Fee Component
                          <select v-model="selectedStudent.feeComponent" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <option value="full5days">Full ELP Program (5days)</option>
                            <option value="mornings5days">All Mornings (5days)</option>
                            <option value="mornings3days">Three Mornings (3days)</option>
                            <option value="littleExplorer3days">Little Explorers (3days)</option>
                            <option value="SS1">Sace Stage 1</option>
                            <option value="SS2">Sace Stage 2</option>
                          </select>
                        </label>
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label class="block text-sm font-medium text-gray-700">
                          Status
                          <div class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <div>
                              <input type="checkbox" v-model="selectedStudent.debenturesCorp" id="debenturesCorp" />
                              <label for="debenturesCorp" class="ml-2">Debentures or Corporate</label>
                            </div>
                            <div>
                              <input type="checkbox" v-model="selectedStudent.staffChild" id="staffChild" />
                              <label for="staffChild" class="ml-2">Child of Staff</label>
                            </div>
                            <div>
                              <input type="checkbox" v-model="selectedStudent.bursary" id="bursary" />
                              <label for="bursary" class="ml-2">Bursary</label>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Family Code
                        <input v-model="selectedStudent.familyCode" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Primary Citizenship
                        <input v-model="selectedStudent.primaryCitizenship" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 First Name
                        <input v-model="selectedStudent.contact1FirstName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 Last Name
                        <input v-model="selectedStudent.contact1LastName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 1 Email
                        <input v-model="selectedStudent.contact1Email" type="email" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 First Name
                        <input v-model="selectedStudent.contact2FirstName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 Last Name
                        <input v-model="selectedStudent.contact2LastName" type="text" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      <div class="col-span-6 sm:col-span-3">
                      <label class="block text-sm font-medium text-gray-700">
                        Contact 2 Email
                        <input v-model="selectedStudent.contact2Email" type="email" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </label>
                      </div>
                      </div>
                      
                    </form>
                  </template>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400" @click="closeDialog">Cancel</button>
<button type="submit" class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500" @click="saveData">Save</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import axios from 'axios';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { onMounted, ref, computed, defineExpose, reactive } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline'


const isBulkLoading = ref(false);
let isLoading = reactive({});
let selectedStudent = ref(null);
let isOpen = ref(false);
let settings = ref(null);

const openDialog = (student) => {
  selectedStudent.value = student;
  isOpen.value = true;
};

const students = ref([]);
const selectedTerm = ref('1');
const invoices = ref([]);

const fetchSettings = async () => {
  try {
    const response = await axios.get('/api/getSettings');
    settings.value = response.data;
  } catch (error) {
    console.error('Error fetching settings:', error);
  }
};

const clientId = process.env.VUE_APP_XERO_CLIENTID; // Replace with your actual client ID
const redirectUri = 'https://pvis.edu.vu/api/auth'; // Replace with your actual redirect URI
const scope = 'openid profile email accounting.contacts accounting.transactions accounting.settings accounting.attachments'; // Replace with the scopes you need
let state = '123'; // Replace with your actual state
const isConnected = ref(false); // Add this line

const connectToXero = () => {
  const authUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  
  console.log(authUrl); // Log the URL

  window.location.href = authUrl; // Open the URL in a new window or tab

};

const checkConnection = async () => {
  try {
    const response = await axios.get('/api/checkConnection');
    isConnected.value = response.data.isConnected;
  } catch (error) {
    console.error('Error checking connection:', error);
  }
};

onMounted(async () => {
  await fetchSettings();
  try {
    const response = await axios.get('/api/getStudents');
    students.value = response.data;
    console.log('Received students data successfully');
  } catch (error) {
    console.error('Error fetching students:', error);
  }
  try {
    const response = await axios.get('/api/getInvoices');
    invoices.value = response.data;
    console.log('Received invoices data successfully');
  } catch (error) {
    console.error('Error fetching invoices:', error);
  }

  // Check Xero connection after loading data
  checkConnection();
});

const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return ''; // return an empty string if dateString is not a valid date
  }
  return date.toISOString().split('T')[0]; // returns date in YYYY-MM-DD format
};

const saveData = async () => {
  try {
    await axios.put('/api/updateStudent', {
      id: selectedStudent.value._id,
      studentData: selectedStudent.value
    });
    isOpen.value = false; // Close the slide-over panel after saving
  } catch (error) {
    console.error('Error updating student:', error);
  }
};

const closeDialog = () => {
  isOpen.value = false; // Close the slide-over panel
};

// Function to format date
const formatDateToYYYYMMDD = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

// Computed property for formatted date
const formattedFirstAttendanceDate = computed({
  get: () => formatDateToYYYYMMDD(selectedStudent.value.firstAttendanceDate),
  set: (value) => { selectedStudent.value.firstAttendanceDate = new Date(value); }
});

const calculateCompletedYears = (firstAttendanceDate) => {
  const startDate = new Date(firstAttendanceDate);
  const endDate = new Date();

  if (isNaN(startDate.getTime())) {
    return ''; // return an empty string if firstAttendanceDate is not a valid date
  }

  let years = endDate.getFullYear() - startDate.getFullYear();

  // Subtract a year if the current date is before the attendance date in the current year
  if (endDate.getMonth() < startDate.getMonth() || 
      (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())) {
    years--;
  }

  return years < 0 ? 0 : years; // returns the number of fully completed years, or 0 if the first attendance date is in the future
};

const TermFee = (level) => {
  const fees = {
    'ELP': 149427,
    'PRE': 149427,
    'FND': 208472,
    'Year 1': 208472,
    'Year 2': 208472,
    'Year 3': 208472,
    'Year 4': 208472,
    'Year 5': 208472,
    'Year 6': 208472,
    'Year 7': 225982,
    'Year 8': 225982,
    'Year 9': 225982,
    'Year 10': 225982,
    'Year 11': 230411,
    'Year 12': 230411,
  };

  return fees[level] || 0;
};

const ressourceLevy = (level) => {
  const fees = {
    'ELP': 21500,
    'PRE': 21500,
    'FND': 22500,
    'Year 1': 22500,
    'Year 2': 22500,
    'Year 3': 22500,
    'Year 4': 22500,
    'Year 5': 22500,
    'Year 6': 22500,
    'Year 7': 27500,
    'Year 8': 27500,
    'Year 9': 27500,
    'Year 10': 27500,
    'Year 11': 42500,
    'Year 12': 52500,
  };

  return fees[level] || 0;
};

const feeComponents = {
  'full5days': 149427,
  'mornings5days': 105987,
  'mornings5daysLE': 136887,
  'mornings3days': 78589,
  'mornings3daysLE': 109489,
  'SS1': 96705,
  'SS2': 159600
};

const calculateFee = (level, feeComponent) => {
  let fee;

  if (['full5days', 'mornings5days', 'mornings5daysLE', 'mornings3days', 'mornings3daysLE'].includes(feeComponent)) {
    fee = feeComponents[feeComponent] || 0;
  } else {
    fee = TermFee(level);
  }
  return fee;
};

const status = (debenturesCorp, staffChild, bursary, firstAttendanceDate) => {
  const years = calculateCompletedYears(firstAttendanceDate);

  if (debenturesCorp) {
    return 'Partners in Education';
  } else if (staffChild) {
    return 'Educators\' Family';
  } else if (bursary) {
    return 'Bursary';
  } else if (years > 2) {
    return 'Long Term';
  } else {
    return 'Regular';
  }
};

const calculateDiscount = (student) => {
  let discountName = '';
  let discountValue = 0;
  let discountType = 'None'; // Default to 'None'

  if (student.currentYear === 'ELP' || student.currentYear === 'PRE') {
    // Early return for ELP or PRE with 'None' as the discountType
    return { discountName, discountValue, discountType };
  }

  if (student.debenturesCorp) {
    discountName = 'Partners in Education';
  } else {
    discountName = 'Discount A';
    discountValue = 33000;
    discountType = 'A';
  }

  if ((student.primaryCitizenship === 'Vanuatu' || student.staffChild) && !(student.currentYear === 'ELP' || student.currentYear === 'PRE')) {
    discountName = 'Discount B';
    discountValue = 60000;
    discountType = 'B';
  }

  return { discountName, discountValue, discountType };
};

const calculateTotal = (student, selectedTerm) => {
  let fee = calculateFee(student.currentYear, student.feeComponent, selectedTerm);
  const { discountValue } = calculateDiscount(student);

  let total = fee - discountValue;

  if (student.bursary || student.staffChild) {
    total *= 0.5; // 50% additional discount
  }

  // Add resource levy and SS1, SS2 fees after applying the discount
  if (selectedTerm === '1') {
    total += ressourceLevy(student.currentYear);
  }
  if (['SS1', 'SS2'].includes(student.feeComponent)) {
    total += feeComponents[student.feeComponent] || 0;
  }

  return total;
};


let studentsWithTotal = computed(() => {
  // Group students by family code and calculate total for each group
  let groups = students.value.reduce((groups, student) => { // Use .value to access ref
    let key = student.familyCode;
    if (!groups[key]) {
      groups[key] = {
        familyCode: key,
        students: [],
        total: 0
      };
    }
    groups[key].students.push(student);
    groups[key].total += calculateTotal(student, selectedTerm.value); // Use .value to access ref
    return groups;
  }, {});

  // Flatten groups into a single array and add total rows
  let result = [];
  for (let familyCode in groups) {
    let group = groups[familyCode];
    result.push(...group.students, {
      isTotalRow: true,
      familyCode: group.familyCode,
      total: group.total
    });
  }

  return result;
});

const generateInvoiceForFamily = async (familyCode) => {
  isLoading[familyCode] = true;
  // Filter students with the same family code
  const studentsInFamily = students.value.filter(student => student.familyCode === familyCode);

  // Prepare line items for each student in the family
  const lineItems = studentsInFamily.map(student => {
    // Prepare the data for the invoice
    const email1 = student.contact1Email;
    const firstName1 = student.contact1FirstName;
    const lastName1 = student.contact1LastName;
    const email2 = student.contact2Email;
    const firstName2 = student.contact2FirstName;
    const lastName2 = student.contact2LastName;
    const currentYear = student.currentYear;
    const { discountName: discountName, discountValue: discountValue } = calculateDiscount(student);
    const heroID = student.heroId;
    const familyID = student.familyCode;
    const studentFirstName = student.firstNameLegal;
    const studentLastName = student.lastNameLegal;
    const status = student.status;
    
    // Recalculate the fee if feeComponent is one of the first five options
    let fee = calculateFee(student.currentYear, student.feeComponent);
    if (['full5days', 'mornings5days', 'mornings5daysLE', 'mornings3days', 'mornings3daysLE'].includes(student.feeComponent)) {
      fee = feeComponents[student.feeComponent] || 0;
    }

     // Calculate ressourceLevy, sace, and otherDiscount
    const resLevy = ressourceLevy(student.currentYear);
    let sace = 0;
    let saceName = '';
    if (['SS1', 'SS2'].includes(student.feeComponent)) {
      sace = feeComponents[student.feeComponent] || 0;
      saceName = student.feeComponent === 'SS1' ? 'Sace Stage 1 Fee' : 'Sace Stage 2 Fee';
    }
    let otherDiscount = 0;
    let otherDiscountName = '';
    let otherDiscountAccountCode = '';
    if (student.bursary) {
      otherDiscount = (fee - discountValue) * 0.5;
      otherDiscountName = 'Bursary';
      otherDiscountAccountCode = '4-1320';
    } else if (student.staffChild) {
      otherDiscount = (fee - discountValue) * 0.5;
      otherDiscountName = 'Staff Discount';
      otherDiscountAccountCode = '4-1230';
    }


    // Return line item
    return {
      email1,
      firstName1,
      lastName1,
      email2,
      firstName2,
      lastName2,
      fee,
      heroID,
      familyID,
      currentYear,
      discountName,
      discountValue,
      studentFirstName,
      studentLastName,
      status,
      resLevy,
      sace,
      saceName,
      otherDiscount,
      otherDiscountName,
      otherDiscountAccountCode,
    };
  });

  const termIndex = selectedTerm.value - 1; // assuming selectedTerm is 1-based
  const invoiceDate = new Date(settings.value.invoiceDates[termIndex]);

  // Calculate due date
  const dueDate = new Date(invoiceDate);
  dueDate.setDate(dueDate.getDate() + Number(settings.value.paymentTerm));

  // Format dates to YYYY-MM-DD
  const invoiceDateString = invoiceDate.toISOString().split('T')[0];
  const dueDateString = dueDate.toISOString().split('T')[0];

  // Calculate total invoice amount
  const totalInvoiceAmount = lineItems.reduce((total, item) => total + item.fee, 0);

  // Extract all heroIDs from the lineItems
  const heroIDs = lineItems.map(item => item.heroID).filter(Boolean);

  // Create a string that concatenates all the heroIDs with a separator
  const reference = heroIDs.join(', ');

  // Generate the invoice
  try {
    const response = await axios.post('/api/generateFamilyInvoice', {
      lineItems,
      totalInvoiceAmount,
      numStudents: studentsInFamily.length,
      reference,
      invoiceDateString,
      dueDateString,
    });

    const invoiceId = response.data.invoiceId;
    console.log('Invoice generated:', invoiceId);

    // Store the invoiceId in your MongoDB database
    await axios.post('/api/storeInvoiceId', { familyID: familyCode, invoiceId });

    // Add the newly generated invoice to the invoices array
    invoices.value.push({ familyCode, invoiceId });

   // Find all students with the matching family code and update their invoice ID
    students.value.forEach(student => {
      if (student.familyCode === familyCode) {
        // Ensure student.Invoices is an object before setting its property
        if (!student.Invoices) {
          student.Invoices = {};
        }
        student.Invoices.Term12024Invoice = invoiceId;
      }
    });

  } catch (error) {
    console.error('Error generating invoice:', error);
  }
  isLoading[familyCode] = false;
};
/* const findInvoiceByFamilyCode = (familyCode) => {
  // Find the first student in the students array that has an invoice with the given familyCode
  const studentWithInvoice = students.value.find(student => student.familyCode === familyCode && student.Invoices && student.Invoices.Term12024Invoice);
  
  // Log the studentWithInvoice for debugging
  console.log('studentWithInvoice:', studentWithInvoice);

  // Return the invoice if found, undefined otherwise
  return studentWithInvoice ? studentWithInvoice.Invoices.Term12024Invoice : undefined;
}; */

// Function to generate invoices in bulk
const generateInvoicesInBulk = async () => {
  isBulkLoading.value = true;
  // Get unique family codes
  const familyCodes = [...new Set(students.value.map(student => student.familyCode))];

  // Generate invoices for each family
  for (let i = 0; i < familyCodes.length; i++) {
    // Regenerate the token every 50 invoices
    if (i % 50 === 0) {
      await regenerateToken();
    }

    try {
      await generateInvoiceForFamily(familyCodes[i]);
    } catch (error) {
      console.error('Error generating invoice for family:', familyCodes[i], error);
      // Continue with the next iteration even if an error occurs
    }
  }
  isBulkLoading.value = false;
};

const regenerateToken = async () => {
  try {
    const response = await axios.get('/api/refreshToken');
    console.log(response.data.message); // Log the success message
  } catch (error) {
    console.error('Error regenerating token:', error);
    // Handle the error, e.g., by showing a notification to the user
  }
};

defineExpose({
  status,
  generateInvoiceForFamily,
  generateInvoicesInBulk,
  isBulkLoading
});

</script>

<style scoped>
.bold-font {
  font-weight: bold;
}
</style>



<span v-else class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"></span>