<template>
<div class="mx-auto max-w-2xl py-2">
      <a href="/billing/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Dashboard <span aria-hidden="true">→</span></a>
    </div>

    <div>
    <button @click="connectToXero" class="btn btn-primary  bg-blue-100 text-blue-600 ring-blue-600 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">Connect to Xero</button>
    <div v-if="isConnected" class="mt-2 bg-green-100 text-green-600 ring-green-600 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">Connected to Xero</div>
    </div>

    <div class="my-12">
    <button @click="syncAllContacts" class="btn btn-primary bg-blue-100 text-blue-600 ring-blue-600 inline-flex items-center rounded-md px-3 py-2 text-sm font-medium ring-1 ring-inset">
      {{ isSyncing ? '...Syncing...' : 'Sync All Contacts' }}
    </button>
  </div>

  <div class="relative isolate px-6 pt-14 lg:px-8">

        <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0">Student</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0">Primary Contact</th>
                <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900">Additional People</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span class="sr-only">Action</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="contact in contacts" :key="contact.id" 
                :class="{
                  'bg-red-200': !contact.primary.Email, 
                  'bg-orange-200': (!contact.primary.FirstName || !contact.primary.LastName) && contact.primary.Email
                }">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ contact.primary.StudentFirstName }} {{ contact.primary.StudentLastName }}</td>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ contact.primary.FirstName }} {{ contact.primary.LastName }} ({{ contact.primary.Email }})</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div v-for="person in contact.additional" :key="person.id">
                    {{ person.FirstName }} {{ person.LastName }}  - {{ person.Email }}
                  </div>
                </td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <button @click="pushToXero(contact)" class="text-indigo-600 hover:text-indigo-900">Push to Xero</button>
                </td>
              </tr>
            </tbody>
          </table>
  </div>


</template>

<script setup>
import axios from 'axios';
import { ref, onMounted } from 'vue';

let contacts = ref([]);
let isSyncing = ref(false);

const getContacts = async () => {
  try {
    const response = await axios.get('/api/getContacts');
    const students = response.data;

    // Transform the student data into contact data
    const contacts = students.map(student => ({
      id: student._id,
      primary: {
        StudentFirstName: student.firstNameLegal,
        StudentLastName: student.lastNameLegal,
        FirstName: student.contact1FirstName,
        LastName: student.contact1LastName,
        Email: student.contact1Email
      },
      additional: [{
        id: student._id,
        FirstName: student.contact2FirstName,
        LastName: student.contact2LastName,
        Email: student.contact2Email
      }]
    }));

    return contacts;
  } catch (error) {
    console.error(error);
  }
};

const pushToXero = async (contact) => {
  try {
    // Include the additional person's email in the request body
    const requestBody = {
      primaryContact: contact.primary,
      additionalPerson: contact.additional[0]
    };

    await axios.post('/api/syncContacts', requestBody);
  } catch (error) {
    console.error(error);
  }
};


const clientId = process.env.VUE_APP_XERO_CLIENTID; // Replace with your actual client ID
const redirectUri = 'https://pvis.edu.vu/api/auth'; // Replace with your actual redirect URI
const scope = 'openid profile email accounting.contacts accounting.transactions accounting.settings accounting.attachments'; // Replace with the scopes you need
let state = '123'; // Replace with your actual state
const isConnected = ref(false); // Add this line

const connectToXero = () => {
  const authUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  
  console.log(authUrl); // Log the URL

  window.location.href = authUrl; // Open the URL in a new window or tab

};

const checkConnection = async () => {
  try {
    const response = await axios.get('/api/checkConnection');
    isConnected.value = response.data.isConnected;
  } catch (error) {
    console.error('Error checking connection:', error);
  }
};

const syncAllContacts = async () => {
  isSyncing.value = true;
  try {
    const processedEmails = new Set();

    for (const contact of contacts.value) {
      // Skip contacts without email or already processed
      if (!contact.primary.Email || processedEmails.has(contact.primary.Email)) {
        console.log('Skipping contact without email or already processed:', contact);
        continue;
      }

      await pushToXero(contact);
      processedEmails.add(contact.primary.Email);
    }

  } catch (error) {
    console.error('Error syncing all contacts to Xero:', error);

  } finally {
    isSyncing.value = false;
  }
};

onMounted(async () => {
  contacts.value = await getContacts();

    // Check Xero connection after loading data
    checkConnection();
});
</script>