<template>
  <div class="bg-white pb-24 sm:pb-32">
    <div class="mx-auto mt-40 max-w-7xl px-6 lg:px-8">
      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Team</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600">We’re a dynamic group of individuals who are passionate about what we do.</p>
      </div>
      <!-- SLT -->
      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl mt-6 font-bold tracking-tight text-gray-900 sm:text-4xl">Our Senior Leadership Team</h2>
      </div>
      <ul role="list" class="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <li v-for="person in slt" :key="person.name">
          <img class="aspect-[2/3] w-full rounded-2xl object-cover" :src="person.imageUrl" alt="" />
          <h3 class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.name }}</h3>
          <p class="text-base leading-7 text-gray-600">{{ person.role }}</p>
          <div class="text-base leading-7 text-gray-600">
            <a :href="'mailto:' + person.email" class="inline-block w-full text-blue-500 hover:underline">
              {{ person.email }}
            </a>
          </div>
        </li>
      </ul>

      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl">Our Early Education Teaching Staff</h2>
      </div>
      <ul role="list" class="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <li v-for="person in eec" :key="person.name">
          <img class="aspect-[2/3] w-full rounded-2xl object-cover" :src="person.imageUrl" alt="" />
          <h3 class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.name }}</h3>
          <p class="text-base leading-7 text-gray-600">{{ person.role }}</p>
          <div class="text-base leading-7 text-gray-600">
            <a :href="'mailto:' + person.email" class="inline-block w-full text-blue-500 hover:underline">
              {{ person.email }}
            </a>
          </div>
        </li>
      </ul>

      <!-- Primary -->
      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl">Our Primary Teaching Staff</h2>
      </div>
      <ul role="list" class="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <li v-for="person in primary" :key="person.name">
          <img class="aspect-[2/3] w-full rounded-2xl object-cover" :src="person.imageUrl" alt="" />
          <h3 class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.name }}</h3>
          <p class="text-base leading-7 text-gray-600">{{ person.role }}</p>
          <div class="text-base leading-7 text-gray-600">
            <a :href="'mailto:' + person.email" class="inline-block w-full text-blue-500 hover:underline">
              {{ person.email }}
            </a>
          </div>
        </li>
      </ul>

      <!-- Secondary -->
      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl">Our Secondary Teaching Staff</h2>
      </div>
      <ul role="list" class="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <li v-for="person in secondary" :key="person.name">
          <img class="aspect-[2/3] w-full rounded-2xl object-cover" :src="person.imageUrl" alt="" />
          <h3 class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.name }}</h3>
          <p class="text-base leading-7 text-gray-600">{{ person.role }}</p>
          <div class="text-base leading-7 text-gray-600">
            <a :href="'mailto:' + person.email" class="inline-block w-full text-blue-500 hover:underline">
              {{ person.email }}
            </a>
          </div>
        </li>
      </ul>

      <!-- Specialist -->
      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl">Our Specialist Staff</h2>
      </div>
      <ul role="list" class="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <li v-for="person in specialist" :key="person.name">
          <img class="aspect-[2/3] w-full rounded-2xl object-cover" :src="person.imageUrl" alt="" />
          <h3 class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.name }}</h3>
          <p class="text-base leading-7 text-gray-600">{{ person.role }}</p>
          <div class="text-base leading-7 text-gray-600">
            <a :href="'mailto:' + person.email" class="inline-block w-full text-blue-500 hover:underline">
              {{ person.email }}
            </a>
          </div>
        </li>
      </ul>

      <!-- Office -->
      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl">Our Office Staff</h2>
      </div>
      <ul role="list" class="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <li v-for="person in office" :key="person.name">
          <img class="aspect-[2/3] w-full rounded-2xl object-cover" :src="person.imageUrl" alt="" />
          <h3 class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.name }}</h3>
          <p class="text-base leading-7 text-gray-600">{{ person.role }}</p>
          <div class="text-base leading-7 text-gray-600">
            <a :href="'mailto:' + person.email" class="inline-block w-full text-blue-500 hover:underline">
              {{ person.email }}
            </a>
          </div>
        </li>
      </ul>

      <!-- Maintenance -->
      <div class="mx-auto lg:mx-0">
        <h2 class="text-3xl mt-20 font-bold tracking-tight text-gray-900 sm:text-4xl">Our Grounds and Maintenance Staff</h2>
      </div>
      <ul role="list" class="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <li v-for="person in maintenance" :key="person.name">
          <img class="aspect-[2/3] w-full rounded-2xl object-cover" :src="person.imageUrl" alt="" />
          <h3 class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.name }}</h3>
          <p class="text-base leading-7 text-gray-600">{{ person.role }}</p>
          <div class="text-base leading-7 text-gray-600">
            <a :href="'mailto:' + person.email" class="inline-block w-full text-blue-500 hover:underline">
              {{ person.email }}
            </a>
          </div>
        </li>
      </ul>

    </div>
  </div>
</template>

<script setup>
import RachelImage from '@/assets/staff/Rachel_Woodley-Higgins_PVIS2022.jpg';
import EdwardImage from '@/assets/staff/Edward_Doyle_PVIS2022.jpg';
import BetsyImage from '@/assets/staff/Betsy_Adams_PVIS2022.jpg';
import JocelyneImage from '@/assets/staff/Jocelyne_Melteres_PVIS2022.jpg';
import ErinaImage from '@/assets/staff/Erina_Waitai_PVIS2022.jpg';
import BrigitteImage from '@/assets/staff/Brigitte_Bailey_PVIS2022.jpg';
import MerelynImage from '@/assets/staff/Merelyn_Peter_PVIS2022.jpg';
// import MonicaImage from '@/assets/staff/Monica_Kalo _PVIS2022.jpg';
import SophieImage from '@/assets/staff/Sophie_Nathaniel_PVIS2022.jpg';
import GenericFImage from '@/assets/staff/GF_PVIS2022.jpg';
// import GenericMImage from '@/assets/staff/GM_PVIS2022.jpg';
import BettyObedImage from '@/assets/staff/Betty_Obed_PVIS2022.jpg';
import CarolineImage from '@/assets/staff/Caroline_Timas_PVIS2022.jpg';
import ChristellaImage from '@/assets/staff/Christella_Kalsrap_PVIS2022.jpg';
import DerrleenImage from '@/assets/staff/Derrleen_Tari_PVIS2022.jpg';
import DylanImage from '@/assets/staff/Dylan_Tari_PVIS2022.jpg';
import EssieImage from '@/assets/staff/Essie_Farro_PVIS2022.jpg';
import EstherImage from '@/assets/staff/Esther_Kamen_PVIS2022.jpg';
import JudithImage from '@/assets/staff/Judith_Hosea_PVIS2022.jpg';
import JulesImage from '@/assets/staff/Jules_Poindy_PVIS2022.jpg';
import MaxiImage from '@/assets/staff/Maxi_PeytonJerram-Snell_PVIS2022.jpg';
import MelImage from '@/assets/staff/Mel_Shem_PVIS2022.jpg';
import NaomiImage from '@/assets/staff/Naomi_Aniel_Shem_PVIS2022.jpg';
import NomaImage from '@/assets/staff/Noma_Lauvanga_PVIS2022.jpg';
import NorahImage from '@/assets/staff/Norah_Pakoa_PVIS2022.jpg';
import PatricianneImage from '@/assets/staff/Patricianne_Shouldice_PVIS2024.jpg';
import PaulineImage from '@/assets/staff/Pauline_Bakeo_PVIS2022.jpg';
import SanreImage from '@/assets/staff/Sanre_Arsen_PVIS2022.jpg';
import TumaiImage from '@/assets/staff/Tumai_Taru_PVIS2022.jpg';
import MarthaImage from '@/assets/staff/Martha_Titek _PVIS2022.jpg';
import NikImage from '@/assets/staff/Nik_Laing_PVIS2022.jpg';
import NeridaImage from '@/assets/staff/Nerida_Stuart_PVIS2022.jpg';
import MatchamImage from '@/assets/staff/Matcham_Caine _PVIS2022.jpg';
import GabrielleImage from '@/assets/staff/Gabrielle_Jackson_PVIS2022.jpg';
import FinaImage from '@/assets/staff/Fina_Samuel_PVIS2022.jpg';
import CathrineImage from '@/assets/staff/Cathrine_Russ_PVIS2022.jpg';
import AnnieImage from '@/assets/staff/Annie_Obed_PVIS2022.jpg';
import KristyImage from '@/assets/staff/Kristy_Barbour_PVIS2022.jpg';
import MolimaliImage from '@/assets/staff/Molimali_Jacob_PVIS2022.jpg';
import RoseImage from '@/assets/staff/Rose_Lobban_PVIS2022.jpg';
import AbyImage from '@/assets/staff/Aby_John_PVIS2022.jpg';
import JanettaImage from '@/assets/staff/Janetta_Achary_PVIS2022.jpg';
import JohnImage from '@/assets/staff/John_Makia_PVIS2022.jpg';
import NorahIshmaelImage from '@/assets/staff/Norah_Ishmael _PVIS2022.jpg';
import JohnHillImage from '@/assets/staff/John_Hill_PVIS2024.jpg';
import AachalDeoImage from '@/assets/staff/Aachal_Deo_PVIS2024.jpg';
import DonnaMcKenzieImage from '@/assets/staff/Donna_Mckenzie_PVIS2024.jpg';
import SheridanHillImage from '@/assets/staff/Sheridan_Hill_PVIS2024.jpg';
import JessicaBreuerImage from '@/assets/staff/Jessica_Breuer_PVIS2024.jpg';
import KimberleyLevershaImage from '@/assets/staff/Kimberley_Leversha_PVIS2024.jpg';
import ScottKircherImage from '@/assets/staff/Scott_Kircher_PVIS2024.jpg';
import MiriaCretierImage from '@/assets/staff/Miria_Cretier_PVIS2024.jpg';
import JenniferWhiteleyImage from '@/assets/staff/Jennifer_Whiteley_PVIS2024.jpg';
import ReginaBaniImage from '@/assets/staff/Regina_Bani_PVIS2024.jpg';
import DarrenSmithImage from '@/assets/staff/Darren_Smith_PVIS2024.jpg';
import LaurenceKuntzImage from '@/assets/staff/Laurence_Kuntz_PVIS2024.jpg';
import ShannonOrdImage from '@/assets/staff/Shannon_Ord_PVIS2024.jpg';
import AnnaMaelImage from '@/assets/staff/Anna_Mael_PVIS2024.jpg';
import DickMolteImage from '@/assets/staff/Dick_Molte_PVIS2024.jpg';
import ViollaSuraiImage from '@/assets/staff/Violla_Surai_PVIS2024.jpg';
import SharonLiatlatmalImage from '@/assets/staff/Sharon_Liatlatmal_PVIS2024.jpg';
import MalonieKalorisImage from '@/assets/staff/Malonie_Kaloris_PVIS2024.jpg';
import LoramakinsImage from '@/assets/staff/Loramakins_Saul_PVIS2024.jpg';
import PaulTomakiakaiImage from '@/assets/staff/Paul_Tomakiakai_PVIS2024.jpg';

const slt = [
  {
    name: 'Rachel Woodley-Higgins',
    role: 'Principal',
    email: 'rachel.woodley-higgins@pvis.edu.vu',
    imageUrl: RachelImage,
  },
  {
    name: 'TBD',
    role: 'Deputy Principal',
    imageUrl: GenericFImage,
  },
  {
    name: 'Patricianne Shouldice',
    role: 'Director of Early Education - Preschool Teacher',
    email: "patricianne.shouldice@pvisv.edu.vu",
    imageUrl: PatricianneImage,
  },
  {
    "name": "Nik Laing",
    "role": "Head of Junior School -  Primary Teacher",
    "email": "nicole.laing@pvis.edu.vu",
    imageUrl: NikImage,
  },
  {
    "name": "Erina Waitai",
    "role": "Head of Middle School - Year 5-6 Teacher",
    "email": "erina.waitai@pvis.edu.vu",
    imageUrl: ErinaImage,
  },
  {
    "name": "John Hill",
    "role": "Senior School, SACE Coordinator, Maths & PE Teacher",
    "email": "john.hill@pvis.edu.vu",
    imageUrl: JohnHillImage,
  },
  {
    "name": "Aachal Deo",
    "role": "Business Manager",
    "email": "aachal.deo@pvis.edu.vu",
    imageUrl: AachalDeoImage,
  },

]

const eec = [
{
    name: 'Patricianne Shouldice',
    role: 'Director of Early Education - Preschool Teacher',
    email: "patricianne.shouldice@pvisv.edu.vu",
    imageUrl: PatricianneImage,
  },
{
    "name": "Donna Mckenzie",
    "role": "Preschool Teacher",
    "email": "donna.mckenzie@pvis.edu.vu",
    imageUrl: DonnaMcKenzieImage,
  },
  {
    "name": "Merelyn Peter",
    "role": "Preschool Teacher",
    "email": "merelyn.peter@pvis.edu.vu",
    imageUrl: MerelynImage,
  },
  {
    "name": "Betsy Adams",
    "role": "EEC Educator",
    imageUrl: BetsyImage,
  },
  {
    "name": "Sophie Nathaniel",
    "role": "EEC Educator",
    imageUrl: SophieImage,
  },
  {
    "name": "Jocelyne Melteres",
    "role": "EEC Educator",
    "email": "",
    imageUrl: JocelyneImage,
  },
  {
    "name": "Loramakins Saul",
    "role": "EEC Educator",
    "email": "",
    imageUrl: LoramakinsImage,
  },
]

const primary = [

  {
    "name": "Christella Kalsrap",
    "role": "Teaching Assistant",
    imageUrl: ChristellaImage,
  },
  {
    "name": "Essie Farro",
    "role": "Teaching Assistant",
    imageUrl: EssieImage,
  },
  {
    "name": "Fina Samuel",
    "role": "Teaching Assistant",
    imageUrl: FinaImage,
  },
  {
    "name": "Tumai Taru",
    "role": "Teaching Assistant",
    imageUrl: TumaiImage,
  },
  {
    "name": "Pauline Bakeo",
    "role": "Teaching Assistant",
    imageUrl: PaulineImage,
  },
  {
    "name": "Betty Obed",
    "role": "Teaching Assistant",
    imageUrl: BettyObedImage,
  },
  {
    "name": "Gabrielle Jackson",
    "role": "Year 3/4 Teacher",
    "email": "gabby.jackson@pvis.edu.vu",
    imageUrl: GabrielleImage,
  },
  {
    "name": "Judith Hosea",
    "role": "Teaching Assistant",
    imageUrl: JudithImage,
  },
  {
    "name": "Cathrine Russ",
    "role": "Year 5/6 Teacher",
    "email": "cathrine.russ@pvis.edu.vu",
    imageUrl: CathrineImage,
  },
  {
    "name": "Annie Obed",
    "role": "Teaching Assistant",
    imageUrl: AnnieImage,
  },
  {
    "name": "Mel Shem",
    "role": "Teaching Assistant",
    imageUrl: MelImage,
  },
  {
    "name": "Brigitte Bailey",
    "role": "Student Leadership Coordinator - Year 5/6 Teacher",
    "email": "brigitte.bailey@pvis.edu.vu",
    imageUrl: BrigitteImage,
  },
  {
    "name": "Erina Waitai",
    "role": "Head of Middle School - Year 5-6 Teacher",
    "email": "erina.waitai@pvis.edu.vu",
    imageUrl: ErinaImage,
  },
  {
    "name": "Dylan Tari",
    "role": "Teaching Assistant",
    imageUrl: DylanImage,
  },
  {
    "name": "Kristy Barbour",
    "role": "Foundation Teacher",
    "email": "kristy.barbour@pvis.edu.vu",
    imageUrl: KristyImage,
  },
  {
    "name": "Kimberley Leversha",
    "role": "Year 1/2 Teacher",
    "email": "kimberley.leversha@pvis.edu.vu",
    imageUrl: KimberleyLevershaImage,
  },
  {
    "name": "Jessica Breuer",
    "role": "Year 3/4 Teacher",
    "email": "jessica.breuer@pvis.edu.vu",
    imageUrl: JessicaBreuerImage,
  },
  {
    "name": "Scott Kircher",
    "role": "Year 3/4 Teacher",
    "email": "scott.kircher@pvis.edu.vu",
    imageUrl: ScottKircherImage,
  },
  {
    "name": "Sheridan Hill",
    "role": "Year 1/2 Teacher",
    "email": "sheridan.hill@pvis.edu.vu",
    imageUrl: SheridanHillImage,
  },
  {
    "name": "Rachael White",
    "role": "Year 1/2 Teacher",
    "email": "rachael.white@pvis.edu.vu",
    imageUrl: GenericFImage,
  },
  {
    "name": "Violla Surai",
    "role": "Teaching Assistant",
    imageUrl: ViollaSuraiImage,
  },
  {
    "name": "Sharon Liatlatmal",
    "role": "Teaching Assistant",
    imageUrl: SharonLiatlatmalImage,
  },
  {
    "name": "Malonie Kaloris",
    "role": "Teaching Assistant",
    imageUrl: MalonieKalorisImage,
  },
  
]


const secondary = [
{
    "name": "Erina Waitai",
    "role": "Head of Middle School - Year 5-6 Teacher",
    "email": "erina.waitai@pvis.edu.vu",
    imageUrl: ErinaImage,
  },
{
    name: 'Edward Doyle',
    role: 'Maths and Science Teacher',
    email: 'edward.doyle@pvis.edu.vu',
    imageUrl: EdwardImage,
  },
  {
    "name": "Nerida Stuart",
    "role": "Science Teacher",
    "email": "nerida.stuart@pvis.edu.vu",
    imageUrl: NeridaImage,
  },
  {
    "name": "Cathrine Russ",
    "role": 'Maths and Science Teacher',
    "email": "cathrine.russ@pvis.edu.vu",
    imageUrl: CathrineImage,
  },
  {
    "name": "Molimali Jacob",
    "role": "English, HASS and Science teacher",
    "email": "molimali.jacob@pvis.edu.vu",
    imageUrl: MolimaliImage,
  },
  {
    "name": "John Hill",
    "role": "Senior School, SACE Coordinator, Maths & PE Teacher",
    "email": "john.hill@pvis.edu.vu",
    imageUrl: JohnHillImage,
  },
  {
    "name": "Miria Cretier",
    "role": "Art Teacher",
    "email": "miria.cretier@pvis.edu.vu",
    imageUrl: MiriaCretierImage,
  },
  {
    "name": "Rose Lobban",
    "role": "Wellbeing Coordinator & Secondary Teacher",
    "email": "rose.lobban@pvis.edu.vu",
    imageUrl: RoseImage,
  },
  {
    "name": "Matcham Caine",
    "role": "HASS and Business Teacher",
    "email": "matcham.caine@pvis.edu.vu",
    imageUrl: MatchamImage,
  },
  {
    "name": "Darren Smith",
    "role": "Maths and Science Teacher",
    "email": "darren.smith@pvis.edu.vu",
    imageUrl: DarrenSmithImage,
  },
  {
    "name": "Jennifer Whiteley",
    "role": "English Teacher",
    "email": "jennifer.whiteley@pvis.edu.vu",
    imageUrl: JenniferWhiteleyImage,
  },
  {
    "name": "Aby John",
    "role": "Teaching Assistant (Sports and Maintenance)",
    imageUrl: AbyImage,
  },
  {
    "name": "Martha Titek",
    "role": "Teaching Assistant (Mathematics and Science)",
    imageUrl: MarthaImage,
  },
]



const specialist = [
  {
    "name": "Laurence Kuntz",
    "role": "French",
    "email": "laurence.kuntz@pvis.edu.vu",
    imageUrl: LaurenceKuntzImage,
  },
  {
    "name": "Maxi PeytonJerram-Snell",
    "role": "Specialist Primary Release",
    "email": "maxi.snell@pvis.edu.vu",
    imageUrl: MaxiImage,
  },
  {
    "name": "Naomi Aniel Shem",
    "role": "Library Coordinator",
    "email": "naomi.shem@pvis.edu.vu",
    imageUrl: NaomiImage,
  },
  {
    "name": "Janetta Achary",
    "role": "Library Coordinator",
    "email": "janetta.achary@pvis.edu.vu",
    imageUrl: JanettaImage,
  },
  {
    "name": "Shannon Ord",
    "role": "School counselor",
    "email": "shannon.ord@pvis.edu.vu",
    imageUrl: ShannonOrdImage,
  },
]

const office = [
  {
    "name": "Aachal Deo",
    "role": "Business Manager",
    "email": "aachal.deo@pvis.edu.vu",
    imageUrl: AachalDeoImage,
  },
  {
    "name": "Noma Iauvanga",
    "role": "Facilities Manager",
    "email": "noma.iauvanga@pvis.edu.vu",
    imageUrl: NomaImage,
  },
  {
    "name": "Regina Bani",
    "role": "Accounting and Administration Office",
    "email": "accounts@pvis.edu.vu",
    imageUrl: ReginaBaniImage,
  },
  {
    "name": "Derrleen Tari",
    "role": "School and Human Resources Manager & Principal’s PA",
    "email": "derrleen.tari@pvis.edu.vu",
    imageUrl: DerrleenImage,
  },
  {
    "name": "John Makia",
    "role": "ICT Support Officer",
    "email": "ict.support@pvis.edu.vu",
    imageUrl: JohnImage,
  },
  {
    "name": "Anna Mael",
    "role": "Receptionist",
    "email": "admin@pvis.edu.vu",
    imageUrl: AnnaMaelImage,
  },
]


const maintenance = [
  {
    "name": "Norah Pakoa",
    "role": "Cleaner",
    "email": null,
    imageUrl: NorahImage,
  },
  {
    "name": "Norah Ishmael",
    "role": "Cleaner",
    "email": null,
    imageUrl: NorahIshmaelImage,
  },
  {
    "name": "Esther Kamen",
    "role": "Cleaner",
    "email": null,
    imageUrl: EstherImage,
  },
  {
    "name": "Caroline Timas",
    "role": "Cleaner",
    "email": null,
    imageUrl: CarolineImage,
  },
  {
    "name": "Jules Poindy",
    "role": "Maintenance & Grounds",
    "email": null,
    imageUrl: JulesImage,
  },
  {
    "name": "Sanre Arsen",
    "role": "Maintenance & Grounds",
    "email": null,
    imageUrl: SanreImage,
  },
  {
    "name": "Paul Tomakiakai",
    "role": "Maintenance & Grounds",
    "email": null,
    imageUrl: PaulTomakiakaiImage,
  },
  {
    "name": "Dick Molte",
    "role": "Maintenance & Grounds",
    "email": null,
    imageUrl: DickMolteImage,
  }
]

</script>