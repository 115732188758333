<template>
  <header class="absolute inset-x-0 top-0 z-50">
    <nav class="relative flex items-center justify-between p-6 lg:px-8 z-100" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="/" class="-m-1.5 p-1.5">
          <span class="sr-only">Port Vila International School</span>
          <img class="h-24 w-auto" :src="logoUrl" alt="Logo" />
        </a>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <div v-for="item in navItems" :key="item.name" class="relative group">
          <Popover v-if="item.submenu && item.submenu.length" class="relative">
            <PopoverButton class="text-sm font-semibold leading-6 text-gray-900">
              {{ item.name }}
            </PopoverButton>
            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                <div class="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                  <template v-for="subItem in item.submenu" :key="subItem.name">
                    <div>
                      <a v-if="subItem.pdfUrl" 
                         :href="subItem.pdfUrl" 
                         :download="subItem.download" 
                         target="_blank" 
                         class="block p-2 hover:text-indigo-600">
                        {{ subItem.name }}
                        <span v-if="subItem.download"></span>
                      </a>
                      <router-link v-else-if="!subItem.submenu || !subItem.submenu.length" :to="subItem.href" class="block p-2 hover:text-indigo-600">{{ subItem.name }}</router-link>
                      <Popover v-else class="relative">
                        <PopoverButton class="block p-2 hover:text-indigo-600">{{ subItem.name }}</PopoverButton>
                        <PopoverPanel class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                          <div class="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                            <template v-for="subSubItem in subItem.submenu" :key="subSubItem.name">
                              <a v-if="subSubItem.pdfUrl" 
                                 :href="subSubItem.pdfUrl" 
                                 :download="subSubItem.download" 
                                 target="_blank" 
                                 class="block p-2 hover:text-indigo-600">
                                {{ subSubItem.name }}
                                <span v-if="subSubItem.download"></span>
                              </a>
                              <router-link v-else :to="subSubItem.href" class="block p-2 hover:text-indigo-600">{{ subSubItem.name }}</router-link>
                            </template>
                          </div>
                        </PopoverPanel>
                      </Popover>
                    </div>
                  </template>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
          <a v-else-if="item.pdfUrl" 
             :href="item.pdfUrl" 
             :download="item.download" 
             target="_blank" 
             class="text-sm font-semibold leading-6 text-gray-900">
            {{ item.name }}
            <span v-if="item.download"></span>
          </a>
          <router-link v-else :to="item.href" class="text-sm font-semibold leading-6 text-gray-900">{{ item.name }}</router-link>
        </div>
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <a href="#" class="text-sm font-semibold leading-6 text-gray-900">HERO Log in (Coming soon)<span aria-hidden="true">&rarr;</span></a>
      </div>
    </nav>
    <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-10" />
      <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <div class="mt-12" v-for="item in navItems" :key="item.name">
                <p 
                  @click="toggleSubmenu(item)" 
                  class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900"
                  :class="{'bg-gray-200': item.showSubmenu, 'hover:bg-gray-50': !item.showSubmenu}"
                >
                  {{ item.name }}
                </p>
                <div v-if="item.showSubmenu" class="space-y-2">
                  <div v-for="subItem in item.submenu" :key="subItem.name">
                    <a v-if="subItem.pdfUrl" 
                       :href="subItem.pdfUrl" 
                       :download="subItem.download" 
                       target="_blank" 
                       class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      {{ subItem.name }}
                      <span v-if="subItem.download"></span>
                    </a>
                    <router-link v-else-if="!subItem.submenu.length" :to="subItem.href" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ subItem.name }}</router-link>
                    <p v-else @click="toggleSubmenu(subItem)" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ subItem.name }}</p>
                    <div v-if="subItem.showSubmenu" class="space-y-2">
                      <div v-for="subSubItem in subItem.submenu" :key="subSubItem.name">
                        <a v-if="subSubItem.pdfUrl" 
                           :href="subSubItem.pdfUrl" 
                           :download="subSubItem.download" 
                           target="_blank" 
                           class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          {{ subSubItem.name }}
                          <span v-if="subSubItem.download"></span>
                        </a>
                        <router-link v-else :to="subSubItem.href" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ subSubItem.name }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="py-6">
              <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">HERO Log in (Coming soon)</a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { Dialog, DialogPanel, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import logoUrl from '../assets/PVIS-logo-blue.svg'

const navItems = ref([]); // Ensure this is reactive

const fetchNavigation = async () => {
  try {
    const response = await axios.get(`https://cdn.contentful.com/spaces/8ayqhzh3p1ua/environments/master/entries?access_token=${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}&content_type=navigation`)
    console.log('Response from Contentful:', response.data);

    const allEntries = response.data.items;
    const assets = response.data.includes?.Asset || [];

    const entryMap = new Map(allEntries.map(item => [item.sys.id, item]));

    const processNavItem = (item) => {
      if (!item || !item.fields) {
        console.warn('Invalid item structure:', item);
        return null;
      }

      const fields = item.fields;
      let pdfUrl = null;
      
      if (fields.pdf && fields.pdf.sys) {
        const pdfAsset = assets.find(asset => asset.sys.id === fields.pdf.sys.id);
        if (pdfAsset && pdfAsset.fields && pdfAsset.fields.file) {
          pdfUrl = `https:${pdfAsset.fields.file.url}`;
        }
      }
      
      return {
        name: fields.name || 'Unnamed Item',
        href: fields.link || '#',
        pdfUrl: pdfUrl,
        download: fields.download || false,
        submenu: Array.isArray(fields.submenu) ? fields.submenu.map(subItem => {
          const subEntry = entryMap.get(subItem.sys.id);
          return subEntry ? processNavItem(subEntry) : null;
        }).filter(Boolean) : []
      };
    };

    const topNavItem = allEntries.find(item => item.fields && item.fields.name === "Top Navigation");

    if (!topNavItem) {
      console.error("Top Navigation item not found");
      return;
    }

    const structuredNavItems = topNavItem.fields.submenu
      .map(subItem => {
        const subEntry = entryMap.get(subItem.sys.id);
        return subEntry ? processNavItem(subEntry) : null;
      })
      .filter(Boolean);

    navItems.value = structuredNavItems;
    console.log('Fetched navigation:', navItems.value);
  } catch (error) {
    console.error('Error fetching navigation from Contentful:', error);
  }
};

onMounted(() => {
  fetchNavigation()
})
/* 
const navigation = reactive([
{ 
    name: 'About us', 
    href: '/about-us',
    submenu: [
      { name: 'Our Team', href: '/our-team' },
      { name: 'Our Board', href: '/our-board' },
      { name: 'Our School', href: '/our-school' },
      { name: 'Our Values', href: '/our-values' },
      { name: 'Our History', href: '/our-history' },
      { name: 'Our Community [Soon]', href: '#' },
      // Add more submenu items here...
    ]
  },
  { name: 'General Information', href: '#',
    submenu: [
      { name: 'Term Dates', href: '/term-dates' },
      { name: 'Term Dates [PDF]', href: '../assets/PVIS-2024-Term-Dates.pdf',  download: 'true' },
      { name: 'After School Activites', href: '/asa' },
      { name: 'Disaster Preparedness', href: '/disaster-preparedness' },
      { name: 'Strategic Plan', href: '/strategic-plan' },
      { name: 'PVIS Yearbook', href: '/yearbook' },
      // Add more submenu items here...
    ] },
    { name: 'Education', href: '#',
  submenu: [
      { name: 'Yumi Strong', href: '/yumi-strong' },
      { name: 'Our Curriculum', href: '/our-curriculum' },
      { name: 'SACE', href: '/sace' },
//      { name: 'PVIS Voices', href: '/our-history' },
     // { name: 'BYOD Laptops', href: '/our-history' },
      // Add more submenu items here...
    ] },
  { name: 'Enrolment', href: '#',
  submenu: [
    { name: 'Enrolment Process', href: '/enrolment-process' },  
    { name: 'School Fees', href: '/school-fees' },   
    //{ name: 'Early Education Center - Preschool', href: '/strategic-plan' },
    //  { name: 'Primary School', href: '/our-school' },
    //  { name: 'Junior Secondary School', href: '/our-school' },
    //  { name: 'Senior Secondary School', href: '/our-history' },
    //  { name: 'Scholarships', href: '/our-history' },
      // Add more submenu items here...
    ] },
    /* { name: 'Community', href: '#',
  submenu: [
        { name: 'School Board', href: '/school-board' },
        { name: 'Year Book', href: '/year-book' },
      
      // Add more submenu items here...
    ] },
    { name: 'Employment', href: '/employment' },
  { name: 'Contact', href: '/contact' },
]) */

const toggleSubmenu = (item) => {
  item.showSubmenu = !item.showSubmenu
}

const mobileMenuOpen = ref(false)
</script>